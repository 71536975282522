/** @format */
import * as api from "../api/index.js";
import * as routes from "../constants/routeNames.js";

export const updateProfile =
  (userInfo, formdata, navigate) => async (dispatch) => {
    console.log(userInfo);
    try {
      const id = userInfo?.result?._id;
      // console.log("id:");
      // console.log(id);
      console.log("Calling update profile");
      const response = await api.updateProfile(id, formdata);
      const data = response?.data;
      dispatch({ type: "UPDATEPROFILE", data });

      return { success: true };
    } catch (error) {
      //   navigate(routes.beginHere);

      return { success: false, error };
    }
  };
