/** @format */

import React, { useEffect, useRef, useState, memo, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import BottomBar from "../components/BottomBar.jsx";
import axios from "axios";
import * as routes from "../constants/routeNames.js";

const serverUrl = `${process.env.REACT_APP_SERVER_BASE_URL}`;

const StepItem = memo(({ number, title, content, isOpen, onClick }) => (
  <div
    className={`w-full md:max-w-[400px] pl-6 pr-1 py-3 rounded-3xl hover:cursor-pointer mb-2 lg:mb-0 text-left ${isOpen
      ? "bg-orange100 hover:bg-opacity-90"
      : "bg-orange900 border border-orange100 hover:ring-2 hover:ring-orange100"
      }`}
    onClick={onClick}
  >
    <h3
      className={`font-poppins4 font-semibold text-md md:text-xl ${isOpen ? "text-orange900" : "text-orange100"
        }`}
    >
      {number}. {title}
    </h3>
    {isOpen && (
      <p className="text-orange900 font-light mx-4 text-xs md:text-sm lg:text-base">
        {content}
      </p>
    )}
  </div>
));

const LandingMain = () => {
  const navigate = useNavigate();

  const [openStep, setOpenStep] = useState(1);
  const [activeButton, setActiveButton] = useState("Equal Ideation");

  const handleGetStarted = () => {
    navigate(routes.login);
  };
  const toggleStep = useCallback((stepNumber) => {
    setOpenStep(stepNumber);
  }, []);

  const handleButtonClick = useCallback((buttonText) => {
    setActiveButton(buttonText);
  }, []);
  const [emailInput, setEmailInput] = useState("");
  const [error, setError] = useState("");
  const postEmail = async (email) => {
    console.log("Posting email:", email);
    try {
      const response = await axios.post(
        `${serverUrl}/api/postEmail`,
        { email: email },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 201) {
        console.log("Email saved successfully");
        // navigate("/signup-successful");
      } else {
        console.error("Failed to save email:", response.data);
        setError("Connection Error");
      }
    } catch (error) {
      console.error("Error posting email:", error);
      setError("Connection Error");
    }
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (validateEmail(emailInput)) {
        setError("");
        postEmail(emailInput);
      } else {
        setError("Please enter a valid email address.");
      }
    }
  };

  const handleSignUpClick = () => {
    if (validateEmail(emailInput)) {
      setError("");
      postEmail(emailInput);
    } else {
      setError("Please enter a valid email address.");
    }
  };

  const stepImageUrls = [
    "https://brainwrite-assets.s3.amazonaws.com/1.+Launch+a+Session.webp",
    "https://brainwrite-assets.s3.amazonaws.com/2.+Customize+Parameters.webp",
    "https://brainwrite-assets.s3.amazonaws.com/3.+Generate+Your+Ideas.webp",
    "https://brainwrite-assets.s3.amazonaws.com/4.+Build+On+Teammates+Ideas.webp",
    "https://brainwrite-assets.s3.amazonaws.com/5.+Vote+Your+Favorites.webp",
    "https://brainwrite-assets.s3.amazonaws.com/6.+Save+the+Results.webp",
  ];

  const brainwritingContent = {
    "Equal Ideation": {
      image: "https://brainwrite-assets.s3.amazonaws.com/equal.svg",
      header: "Equal Ideation",
      text: "Brainwriting is a brainstorming method for equal participation during team idea generation. Unlike traditional brainstorming, it emphasizes silent, written idea sharing.",
    },
    "Less Bias": {
      image: "https://brainwrite-assets.s3.amazonaws.com/less-bias.svg",
      header: "Less Bias",
      text: "Brainwriting is anonymous to reduce the influence of dominant voices and encourages diverse thinking.",
    },
    "6-3-5": {
      image: "https://brainwrite-assets.s3.amazonaws.com/more-ideas.svg",
      header: "6-3-5",
      text: "The commonly used 6-3-5 format (6 participants, 3 ideas each, 5 minutes per round), provides a focused time-efficient framework to generate 108 ideas in just 30 minutes.",
    },
    "Problem Solving": {
      image: "https://brainwrite-assets.s3.amazonaws.com/prob-solving.svg",
      header: "Problem Solving",
      text: "Businesses and teams use brainwriting to tackle complex challenges, develop new products, and devise strategies.",
    },
  };

  const AnimatedStatistic = () => {
    const [height, setHeight] = useState(0);
    const statRef = useRef(null);
    const rafRef = useRef(null);
    const [colorShift, setColorShift] = useState(1);

    useEffect(() => {
      const handleScroll = () => {
        if (statRef.current) {
          const element = statRef.current;
          const elementTop = element.getBoundingClientRect().top;
          const elementHeight = element.offsetHeight;
          const windowHeight = window.innerHeight;

          if (elementTop < windowHeight && elementTop > -elementHeight) {
            const scrollPercentage =
              (windowHeight - elementTop) / (windowHeight + elementHeight);
            rafRef.current = requestAnimationFrame(() => {
              setHeight(Math.min(scrollPercentage * 140, 100));
              setColorShift(400 * (0.7 - scrollPercentage));
            });
          }
        }
      };

      window.addEventListener("scroll", handleScroll);
      handleScroll();

      return () => {
        window.removeEventListener("scroll", handleScroll);
        if (rafRef.current) {
          cancelAnimationFrame(rafRef.current);
        }
      };
    }, []);

    const wavePosition = 100 - height;
    const wavePath = `
  M0,${wavePosition}
  C20,${wavePosition + 12} -30,${wavePosition - 20} 30,${wavePosition - 8}
  S100,${wavePosition + 12} 140,${wavePosition - 8}
  S180,${wavePosition - 8} 250,${wavePosition + 8}
  L200,100 L0,100 Z
`;

    return (
      <div
        ref={statRef}
        className="flex flex-col items-start md:items-center xl:items-end md:flex-row w-full h-full py-0 md:py-10 -mt-5 md:mt-0 lg:max-w-[90%]"
      >
        <div className="w-full h-full max-w-[480px] -mx-[13vw] md:mx-auto min-w-[400px]">
          <svg viewBox="0 0 200 100" className="w-full h-auto">
            <defs>
              <clipPath id="textClip">
                <text
                  x="50%"
                  y="50%"
                  textAnchor="middle"
                  alignmentBaseline="central"
                  className="font-poppins4 font-ultrabold text-7xl md:text-8xl text-left"
                >
                  42%
                </text>
              </clipPath>
            </defs>
            <text
              x="50%"
              y="50%"
              textAnchor="middle"
              alignmentBaseline="central"
              fill="#140800"
              className="font-poppins4 font-ultrabold text-7xl md:text-8xl"
            >
              42%
            </text>
            <path
              d={wavePath}
              // fill={`rgba(${colorShift}, 30, ${256 - colorShift})`}
              fill="#E0752D"
              clipPath="url(#textClip)"
            />
          </svg>
        </div>
        <div className="">
          <h3 className="font-poppins4 text-4xl sm:text-5xl xl:text-7xl font-semibold text-orange900 -mt-10 md:mt-0 text-left">
            More <br></br>
            <span className="text-orange500 font-poppins4">Original&nbsp;</span>
            Ideas.
          </h3>
          <p className="mt-4 text-orange900 max-w-2xl text-base text-left">
            Research has shown that brainwriting generates 42% more original
            ideas than traditional brainstorming methods.
          </p>
        </div>
      </div>
    );
  };

  return (
    // <div className="bg-yellow-100 md:bg-green-100 lg:bg-green-200 overflow-x-hidden">
    <div className="overflow-x-hidden bg-gradient-to-r from-profile to-white">
      {/* Democratize ideation */}
      <div className="mx-5 md:mx-20">
        <div className="w-full min-h-screen flex-col lg:flex lg:flex-row lg:justify-between lg:pt-48">
          <div className="w-full pt-40 sm:pt-32 lg:pt-0 flex flex-col items-center text-center lg:text-left lg:items-start max-w-full lg:max-w-[40%]">
            <h1 className="font-poppins4 text-3xl md:text-4xl font-normal mb-2 text-orange900">
              Democratize Ideation.
            </h1>
            <h2 className="leading-7 mb-10 text-orange900 max-w-72 font-light text-base md:text-lg">
              Empower Every Voice in Your Business with Brainwriting.
            </h2>
            <button
              className="bg-orange500 hover:bg-orange400 active:ring-2 active:ring-orange400 px-8 py-3 rounded-full text-white text-sm font-semibold flex items-center justify-between gap-x-2 mb-10 group"
              onClick={handleGetStarted}
            >
              Get Started
              <span className="transition-transform duration-300 ease-in-out transform group-hover:translate-x-2">
                <img
                  src="https://brainwrite-assets.s3.amazonaws.com/gs-arrow.svg"
                  alt="get started"
                  className="w-4"
                />
              </span>
            </button>
          </div>
          <div className="flex mt-16 sm:mt-0 lg:-mt-12 xl:-mt-24 w-full md:w-[50%] items-center">
            <img
              src="https://brainwrite-assets.s3.amazonaws.com/Product+Display+(1).webp"
              alt="example product screenshots"
              className="h-auto w-full object-contain mb-0 md:mb-4"
            />
          </div>
        </div>
      </div>

      {/* Innovate quietly, collaborate powerfully */}
      <div className="w-full min-h-screen bg-orange900 flex items-center justify-center">
        <div className="pt-14 md:pt-8 lg:pt-12 xl:pt-8 mx-5 md:mx-20 mb-5 md:mb-10">
          <h2 className="text-left font-poppins4 text-3xl md:text-4xl font-normal text-white mb-2 -mt-10 md:-mt-5">
            Innovate Quietly, Collaborate{" "}
            <span className="font-semibold font-poppins4">Powerfully.</span>
          </h2>
          <h3 className="text-left leading-7 mb-5 text-white text-base md:text-lg">
            See how Brainwrite.io works, step-by-step
          </h3>
          {/* steps to launch a session */}
          <div className="flex flex-col lg:flex-row w-full h-full gap-5">
            <div className="flex flex-col items-center justify-between lg:items-start flex-grow">
              <StepItem
                number={1}
                title="Start your session"
                content="Select a preset, then enter the question you would like your team to answer."
                isOpen={openStep === 1}
                onClick={() => toggleStep(1)}
              />
              <StepItem
                number={2}
                title="Customize Parameters"
                content="Adjust session settings to fit your team's needs."
                isOpen={openStep === 2}
                onClick={() => toggleStep(2)}
              />
              <StepItem
                number={3}
                title="Generate Your Ideas"
                content="Writing ideas independently reduces the influence of dominant voices and encourages diverse thinking."
                isOpen={openStep === 3}
                onClick={() => toggleStep(3)}
              />
              <StepItem
                number={4}
                title="Build on Teammates' Ideas"
                content="Ideas rotate between teammates to be built upon or inspire new thoughts."
                isOpen={openStep === 4}
                onClick={() => toggleStep(4)}
              />
              <StepItem
                number={5}
                title="Iterate and Analyze"
                content="Systematically vote, evaluate, and prioritize the best concepts."
                isOpen={openStep === 5}
                onClick={() => toggleStep(5)}
              />
              <StepItem
                number={6}
                title="Save the Results"
                content="Categorize your best ideas and revisit them at any time."
                isOpen={openStep === 6}
                onClick={() => toggleStep(6)}
              />
            </div>
            <div className="w-full flex-grow max-h-[500px]">{openStep && (
              <div className="rounded-3xl bg-profile flex items-center justify-center overflow-hidden h-full w-full max-w-[95%]">
                <img
                  src={stepImageUrls[openStep - 1]}
                  alt={`Step ${openStep} illustration`}
                  className="object-contain"
                />
              </div>

            )}
              <div
                className="flex items-center hover:cursor-pointer group active:opacity-80 mt-2"
                onClick={handleGetStarted}
              >
                <div className="font-poppins4 text-white underline text-base md:text-2xl mr-2 md:mr-4 lg:mr-5 whitespace-nowrap">
                  Experience first-hand
                </div>
                <img
                  src="https://brainwrite-assets.s3.amazonaws.com/gs-arrow.svg"
                  alt="get started"
                  className="w-4 md:w-6 transition-transform duration-300 group-hover:translate-x-4"
                />
              </div></div>
          </div>
        </div>

      </div>

      <div className="w-full min-h-screen bg-orange100 flex items-center justify-center">
        <div className="flex-col w-full h-full mx-5 md:mx-20">
          <h2 className="text-orange900 text-3xl md:text-4xl font-poppins4 font-semibold mb-4 mt-0 md:mt-10 text-left">
            What is Brainwriting?
          </h2>
          <div className="flex-row bg-white py-6 px-3 rounded-3xl text-xs md:mt-5 space-y-5 min-h-[300px] sm:min-h-0">
            <div className="flex flex-wrap justify-center md:justify-start lg:space-x-4">
              {Object.keys(brainwritingContent).map((buttonText) => (
                <div className="">
                  <button
                    key={buttonText}
                    className={`rounded-full w-[152px] h-[42px] m-1 text-sm lg:text-base font-medium whitespace-nowrap ${activeButton === buttonText
                      ? "bg-orange200"
                      : "bg-graybrown150"
                      }`}
                    onClick={() => setActiveButton(buttonText)}
                  >
                    {buttonText}
                  </button>
                </div>
              ))}
            </div>
            {activeButton && (
              <div className="ml-10 mt-4  flex flex-row items-start md:items-center">
                <LazyLoadImage
                  src={brainwritingContent[activeButton].image}
                  alt={activeButton}
                  className="w-[72px] h-[72px] md:w-[120px] md:h-[120px]"
                />
                <div className="ml-5">
                  <h3 className="font-poppins4 font-semibold text-base lg:text-2xl mb-1 text-left">
                    {brainwritingContent[activeButton].header}
                  </h3>
                  <p className="max-w-[190px] sm:max-w-full lg:max-w-[550px] font-light text-xs lg:text-base text-left">
                    {brainwritingContent[activeButton].text}
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className="w-full flex justify-start md:justify-center">
            <AnimatedStatistic />
          </div>
        </div>
      </div>

      {/* last screen */}
      <div className="min-h-screen flex items-center justify-center relative h-screen w-screen overflow-hidden">
        <img
          src="https://brainwrite-assets.s3.amazonaws.com/bg-waves.svg"
          alt="Background waves"
          className="absolute inset-0 w-full h-full object-cover"
          loading="lazy"
        />
        <div className="p-12 bg-orange100 flex-col items-center justify-center mx-5 md:mx-20 rounded-2xl mb-64 md:sm-56 relative z-10 max-w-[90%]">
          <h2 className="font-medium text-base md:text-xl text-center mb-4 font-poppins4 ">
            Start Innovating Distraction-Free
          </h2>
          <p className="text-center font-light max-w-[313px] mb-8 text-xs md:text-base font-poppins">
            Click here to be one of the first users to experience our
            cutting-edge brainwriting tool!{" "}
          </p>
          <div className="mx-auto flex w-[200px] bg-white rounded-full p-1 overflow-hidden">
            {/* <input
              type="email"
              placeholder="Your Email Address"
              value={emailInput}
              onChange={(e) => {
                setEmailInput(e.target.value);
                setError("");
              }}
              onKeyPress={handleKeyPress}
              className="flex-grow max-w-[70%] px-4 py-4 rounded-full border-none focus:outline-none text-xs md:text-sm placeholder-graybrown400"
            />
            <button
              className="rounded-full min-w-[84px] sm:w-[112px] px-4 py-2 bg-orange200 hover:opacity-80 active:bg-orange500 active:text-white text-orange900 text-xs font-medium tracking-widest"
              onClick={handleSignUpClick}
            >
              SUBMIT
            </button> */}

            <button
              className="rounded-full min-w-[84px] w-full px-4 py-4 bg-orange200 hover:opacity-80 active:bg-orange500 active:text-white text-orange900 text-xs font-medium tracking-widest"
              onClick={handleGetStarted}
            >
              GET STARTED
            </button>
          </div>
          {/* {error && (
            <div className="regular text-red-500 mb-[3vw] sm:mb-[3vw] md:mb-[2.5vw] lg:mb-[2vw] xl:mb-[1.5vw] text-xs sm:text-xs ready-to-transform-error">
              {error}
            </div>
          )} */}
        </div>
      </div>

      <BottomBar />
    </div>
  );
};

export default LandingMain;
