/** @format */

import { configureStore } from "@reduxjs/toolkit";
import { reducers } from "./index.js"; // Ensure you have your rootReducer

const store = configureStore({
  reducer: reducers,
});

export default store;
