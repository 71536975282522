/** @format */

import React, { useState, useEffect } from "react";
import BottomBar from "../../components/BottomBar.jsx";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  GoogleLogin,
  GoogleOAuthProvider,
  useGoogleLogin,
} from "@react-oauth/google";
import jwtDecode from "jwt-decode"; // Corrected import
import { useDispatch } from "react-redux";
import { signin, signup, checkUserAuth, googleAuth } from "../../actions/auth";
import zxcvbn from "zxcvbn"; // Import zxcvbn
import VerifyEmail from "./VerifyEmail.js";
import * as routes from "../../constants/routeNames.js";

const serverUrl = `${process.env.REACT_APP_SERVER_BASE_URL}`;
const googleClientID = process.env.REACT_APP_GOOGLE_CLIENT_ID.trim();

const Signup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [error, setError] = useState("");
  const [profileResponse, setProfileResponse] = useState();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    fullName: "",
  });
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordRequirements, setPasswordRequirements] = useState({
    hasUpperCase: false,
    hasLowerCase: false,
    hasNumber: false,
    hasSymbol: false,
    minLength: false,
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

    if (e.target.name === "password") {
      const value = e.target.value;
      const strength = zxcvbn(value).score;
      setPasswordStrength(strength);

      setPasswordRequirements({
        hasUpperCase: /[A-Z]/.test(value),
        hasLowerCase: /[a-z]/.test(value),
        hasNumber: /\d/.test(value),
        hasSymbol: /[!@#$%^&*(),.?":{}|<>]/.test(value),
        minLength: value.length >= 8,
      });
    }
    setError("");
  };

  const handleSignInClick = () => {
    navigate(routes.login);
  };

  const handleSubmitClick = (e) => {
    e.preventDefault();
    if (formData.fullName) {
      if (validateEmail(formData.email)) {
        if (formData.password === formData.confirmPassword) {
          if (validatePassword()) {
            setError("");
            handleSubmit();
          } else {
            handleError("Password does not meet the requirements.");
          }
        } else {
          handleError("Passwords do not match.");
        }
      } else {
        handleError("Please enter a valid email.");
      }
    } else {
      handleError("Please enter a valid name.");
    }
  };

  const handleError = (err) => {
    console.log(err);
    setError(err);
    setFormData({ ...formData, password: "", confirmPassword: "" });
    setPasswordStrength(0);
    setPasswordRequirements({
      hasUpperCase: false,
      hasLowerCase: false,
      hasNumber: false,
      hasSymbol: false,
      minLength: false,
    });
  };

  const handleSubmit = async () => {
    try {
      console.log(formData);
      await dispatch(signup(formData, navigate));
    } catch (error) {
      handleError(error.message || "An error occurred during signup.");
    }
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePassword = () => {
    return (
      passwordRequirements.hasUpperCase &&
      passwordRequirements.hasLowerCase &&
      passwordRequirements.hasNumber &&
      passwordRequirements.hasSymbol &&
      passwordRequirements.minLength
    );
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const token = tokenResponse?.access_token;
      try {
        const profile = await axios.get(
          "https://www.googleapis.com/oauth2/v1/userinfo?alt=json",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const result = profile.data;
        console.log(result);
        dispatch(googleAuth(token, result, navigate));
      } catch (error) {
        handleError("Google login failed. Please try again.");
      }
    },
    onError: (error) => {
      handleError("Google login failed. Please try again.");
    },
  });

  const getPasswordStrengthLabel = (score) => {
    console.log(score);
    switch (score) {
      case 0:
        return "Very Weak";
      case 1:
        return "Weak";
      case 2:
        return "Fair";
      case 3:
        return "Good";
      case 4:
        return "Strong";
      default:
        return "";
    }
  };
  const getPasswordStrengthColor = (score) => {
    switch (score) {
      case 0:
        return "bg-red-500";
      case 1:
        return "bg-yellow-500";
      case 2:
        return "bg-yellow-500";
      case 3:
        return "bg-blue-500";
      case 4:
        return "bg-green-500";
      default:
        return "bg-gray-300";
    }
  };
  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const user = JSON.parse(localStorage.getItem("profile"));
        if (user && user.token) {
          const isAuthenticated = await dispatch(checkUserAuth(user.token));
          if (isAuthenticated) {
            navigate("/beginHere");
          }
        }
      } catch (error) {
        console.error("Error checking user authentication", error);
      }
    };

    checkAuthStatus();
  }, [dispatch, navigate]);

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex flex-col items-center justify-center flex-1 bg-white">
        <div className="max-w-md w-full mt-14 md:mt-10">
          <h1 className="text-2xl font-semibold text-center text-orange900 mb-10 mt-10 md:mt-20">
            Sign Up
          </h1>
          <div className="flex flex-col align-middle justify-center items-center">
            <GoogleOAuthProvider clientId={googleClientID}>
              <button
                onClick={googleLogin}
                className="w-[335px] xl:w-[400px] mx-auto py-2 text-orange900 font-regular bg-white border border-graybrown400 rounded-md hover:bg-orange100 text-lg flex items-center justify-center"
              >
                <img
                  src="https://brainwrite-assets.s3.amazonaws.com/google-icon.svg"
                  alt="Google logo"
                  className="w-6 h-6 mr-2"
                />
                Sign up with Google
              </button>
            </GoogleOAuthProvider>
          </div>
          <div className="w-[335px] xl:w-[400px] mx-auto relative mt-6">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-graybrown400"></div>
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-white text-orange900 font-bold">OR</span>
            </div>
          </div>
          <form
            className="w-[335px] xl:w-[400px] mx-auto mt-6 space-y-6"
            onSubmit={handleSubmitClick}
          >
            <div className="relative">
              <label htmlFor="fullName" className="sr-only">
                Full Name
              </label>
              <input
                id="fullName"
                name="fullName"
                placeholder="Full Name"
                className="w-full px-3 py-2 mt-1 border border-graybrown400 rounded focus:outline-none focus:ring-orange500 focus:border-orange500 font-medium placeholder-graybrown400 text-orange900"
                onChange={handleChange}
              />
            </div>

            <div className="relative">
              <label htmlFor="email" className="sr-only">
                Email address
              </label>
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <img
                  src="https://brainwrite-assets.s3.amazonaws.com/email-icon.svg"
                  alt="Email icon"
                  className="w-5 h-5"
                />
              </div>
              <input
                id="email"
                name="email"
                type="email"
                placeholder="Email address"
                className="w-full px-10 py-2 mt-1 border border-graybrown400 rounded focus:outline-none focus:ring-orange500 focus:border-orange500 font-medium placeholder-graybrown400 text-orange900"
                onChange={handleChange}
              />
            </div>
            <div className="relative">
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <img
                  src="https://brainwrite-assets.s3.amazonaws.com/Vector.svg"
                  alt="Password icon"
                  className="w-5 h-5"
                />
              </div>
              <input
                id="password"
                name="password"
                type="password"
                placeholder="Password"
                className="w-full px-10 py-2 mt-1 border border-graybrown400 rounded focus:outline-none focus:ring-orange500 focus:border-orange500 font-medium placeholder-graybrown400 text-orange900"
                onChange={handleChange}
                value={formData.password}
              />
            </div>
            <div className="mt-2">
              <div
                className={`h-2 rounded ${getPasswordStrengthColor(
                  passwordStrength
                )} ${formData.password && formData.password != ""
                  ? "block"
                  : "hidden"
                  }`}
                style={{ width: `${(passwordStrength + 1) * 20}%` }}
              ></div>
              <p
                className={`text-xs text-orange900 mt-1 ${formData.password && formData.password != ""
                  ? "block"
                  : "hidden"
                  } `}
              >
                {getPasswordStrengthLabel(passwordStrength)}
              </p>
              <ul
                className={`text-xs text-orange900 mt-1 ${formData.password && formData.password != ""
                  ? "block"
                  : "hidden"
                  }`}
              >
                {!passwordRequirements.hasUpperCase && (
                  <li>At least one uppercase letter</li>
                )}
                {!passwordRequirements.hasLowerCase && (
                  <li>At least one lowercase letter</li>
                )}
                {!passwordRequirements.hasNumber && (
                  <li>At least one number</li>
                )}
                {!passwordRequirements.hasSymbol && (
                  <li>At least one symbol</li>
                )}
                {!passwordRequirements.minLength && (
                  <li>At least 8 characters</li>
                )}
              </ul>
            </div>
            <div className="relative">
              <label htmlFor="confirmPassword" className="sr-only">
                Confirm Password
              </label>
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <img
                  src="https://brainwrite-assets.s3.amazonaws.com/Vector.svg"
                  alt="Confirm Password icon"
                  className="w-5 h-5"
                />
              </div>
              <input
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                placeholder="Confirm Password"
                className="w-full px-10 py-2 mt-1 border border-graybrown400 rounded focus:outline-none focus:ring-orange500 focus:border-orange500 font-medium placeholder-graybrown400 text-orange900"
                onChange={handleChange}
                value={formData.confirmPassword}
              />
            </div>
            <div className="regular text-red-500 mb-[3vw] sm:mb-[3vw] md:mb-[2.5vw] lg:mb-[2vw] xl:mb-[1.5vw] text-xs sm:text-xs ready-to-transform-error">
              {error}
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember_me"
                  name="remember_me"
                  type="checkbox"
                  className="w-4 h-4 checked:bg-orange500 border-orange900 focus:ring-orange500"
                />
                <label
                  htmlFor="remember_me"
                  className="block ml-2 text-xs text-orange900"
                >
                  Remember me
                </label>
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="w-full px-4 py-3 text-white bg-orange500 rounded-3xl hover:bg-orange300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bwblue font-semibold"
              >
                Continue
              </button>
            </div>
          </form>
          <div className="mt-4 text-center mb-10">
            <span className="text-orange900 text-xs">
              Already have an account?{" "}
            </span>
            <button
              type="button"
              className="font-medium text-orange500 underline hover:text-orange300 focus:outline-none text-xs"
              onClick={handleSignInClick}
            >
              Sign In
            </button>
          </div>
        </div>
      </div>
      <BottomBar />
    </div>
  );
};

export default Signup;
