/** @format */

import React, { useState, useEffect, useCallback } from "react";

const Timer = ({ endTime, onTimerEnd }) => {
  const [timeLeft, setTimeLeft] = useState(0);
  const [loading, setLoading] = useState(true);

  const updateTimer = useCallback(() => {
    const currentTime = Date.now();
    const timeLeft = Math.max(0, endTime - currentTime);
    setTimeLeft(Math.floor(timeLeft / 1000));
    if (timeLeft === 0) {
      onTimerEnd();
    }
  }, [endTime, onTimerEnd]);

  useEffect(() => {
    if (endTime) {
      const timerId = setInterval(updateTimer, 1000);
      setLoading(false);
      return () => clearInterval(timerId);
    }
  }, [endTime, updateTimer]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  return !loading ? (
    <div className="">
      <h1>{formatTime(timeLeft)}</h1>
    </div>
  ) : null;
};

export default Timer;
