/** @format */

import { AUTH, LOGOUT } from "../constants/actionTypes";
import * as api from "../api/index.js";
import * as routes from "../constants/routeNames.js";

export const signin = (formData, navigate) => async (dispatch) => {
  console.log("Signing in");
  console.log(formData);

  try {
    const response = await api.signIn(formData);
    const data = response?.data;
    dispatch({ type: AUTH, data });
    navigate(routes.beginHere);
  } catch (error) {
    console.log("Error in signin:", error);
    const errorMessage = error.response?.data?.message || error.message;
    throw new Error(errorMessage);
  }
};

export const signup = (formData, navigate) => async (dispatch) => {
  try {
    const response = await api.signUp(formData);
    const data = response?.data;
    dispatch({ type: AUTH, data });
    navigate(routes.beginHere);
  } catch (error) {
    console.log("Error in signup:", error);
    const errorMessage = error.response?.data?.message || error.message;
    throw new Error(errorMessage);
  }
};
export const googleAuth =
  (tokenResponse, profile, navigate) => async (dispatch) => {
    // console.log("In googleAuth action. Token response:");
    // console.log(tokenResponse);
    // console.log("PROFILE: ");
    // console.log(profile);
    try {
      const response = await api.googleAuth({
        tokenInput: tokenResponse,
        profile,
      });
      const data = response?.data;
      dispatch({ type: AUTH, data });
      navigate(routes.beginHere);
    } catch (error) {
      // console.log("Error in Google Auth:", error);
    }
  };

export const checkUserAuth = (userInfo, navigate) => async (dispatch) => {
  // console.log("Checking user auth");
  try {
    const id = userInfo?.result?._id;

    const response = await api.checkAuth();
    const data = response?.data;
    // console.log("User authenticated. ");
    // console.log(data);
    return true;
  } catch (error) {
    console.log("User no longer authenticated:", error);
    dispatch({ type: LOGOUT });
    return false;
    // navigate(routes.login);
  }
};
