/** @format */

import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Stage,
  Layer,
  Group,
  Rect,
  Text,
  Image,
  Line,
  Circle,
} from "react-konva";
import { useDispatch } from "react-redux";
import { getVoterIdeas } from "../../actions/sessions.js";

import socket from "../../socket.js"; // Import the shared socket instance
import VotingIntro from "./VotingIntro.js";

import { checkUserAuth } from "../../actions/auth.js";
import { useNavigate } from "react-router-dom";
import * as routes from "../../constants/routeNames.js";
import "./MainFlow.css";

const getBoundary = (scale, screenWidth, screenHeight) => {
  const expansionFactor = 0.3; // This controls how much the boundaries expand
  return {
    LEFT: -screenWidth * (0.5 + scale * expansionFactor),
    RIGHT: screenWidth * (2.5 + scale * expansionFactor),
    TOP: -screenHeight * (0.5 + scale * expansionFactor),
    BOTTOM: screenHeight * (2.5 + scale * expansionFactor),
  };
};

const DotVoting = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));
  // const [allIdeas, setAllIdeas] = useState([
  //   [
  //     { text: "idea a 0", liked: false, id: 1 },
  //     {
  //       text: "idea a 1 idea a 1 idea a 1 idea a 1 idea a 1 idea a 1 idea a 1 idea a 1 idea a 1 idea a 1 idea a 1 idea a 1 idea a 1 idea a 1 idea a 1 idea a 1",
  //       liked: false,
  //       id: 2,
  //     },
  //     {
  //       text: "idea a 2 idea a 1 idea a 1 idea a 1 idea a 1 idea a 1 idea a 1 idea a 1 idea a 1 idea a 1 idea a 1 idea a 1 idea a 1 idea a 1 idea a 1 idea a 1 idea a 1",
  //       liked: false,
  //       id: 3,
  //     },
  //     { text: "idea a 3", liked: false, id: 4 },
  //     { text: "idea a 4", liked: false, id: 5 },
  //   ],
  //   [
  //     {
  //       text: "This is idea b 0, dude, what if we like, used AI, or something, in our product, man???? ",
  //       liked: false,
  //       id: 6,
  //     },
  //     { text: "idea b 1", liked: false, id: 7 },
  //   ],
  //   [{ text: "idea c 0", liked: false, id: 8 }],
  //   [{ text: "idea d 0", liked: false, id: 9 }],
  //   [{ text: "idea e 0", liked: false, id: 10 }],
  //   [
  //     { text: "idea f 0", liked: false, id: 11 },
  //     { text: "idea f 1", liked: false, id: 12 },
  //     { text: "idea f 2", liked: false, id: 13 },
  //     { text: "idea f 3", liked: false, id: 14 },
  //     { text: "idea f 4", liked: false, id: 15 },
  //     { text: "idea f 5", liked: false, id: 16 },
  //     { text: "idea f 6", liked: false, id: 17 },
  //     { text: "idea f 7", liked: false, id: 18 },
  //     {
  //       text: "idea f 8 idea f 8 idea f 8 idea f 8 idea f 8 idea f 8 idea f 8 idea f 8 idea f 8 idea f 8 idea f 8 idea f 8 idea f 8 idea f 8 idea f 8 idea f 8 idea f 8 idea f 8 idea f 8 idea f 8 idea f 8 idea f 8 idea f 8 idea f 8",
  //       liked: false,
  //       id: 19,
  //     },
  //   ],
  //   [{ text: "idea g 0", liked: false, id: 20 }],
  //   [{ text: "idea h 0", liked: false, id: 21 }],
  //   [{ text: "idea i 0", liked: false, id: 22 }],
  //   [{ text: "idea j 0", liked: false, id: 23 }],
  //   [{ text: "idea k 0", liked: false, id: 24 }],
  //   [{ text: "idea l 0", liked: false, id: 25 }],
  //   [
  //     { text: "idea m 0", liked: false, id: 26 },
  //     { text: "idea m 1", liked: false, id: 27 },
  //     { text: "idea m 2", liked: false, id: 28 },
  //     { text: "idea m 3", liked: false, id: 29 },
  //     { text: "idea m 4", liked: false, id: 30 },
  //     { text: "idea m 5", liked: false, id: 31 },
  //   ],
  // ]);
  const [allIdeas, setAllIdeas] = useState([[]]);

  const [userId, setUserId] = useState("");
  const [roomCode, setRoomCode] = useState(
    JSON.parse(localStorage.getItem("room"))?.roomCode
  );

  const [submissionCount, setSubmissionCount] = useState(0);
  const [totalParticipants, setTotalParticipants] = useState(0);
  const MAX_VOTES = 12; // Use this to set a max number of votes
  const [remainingLikes, setRemainingLikes] = useState(MAX_VOTES);

  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const getVoterInfo = async () => {
    const data = await dispatch(getVoterIdeas(user, navigate));
    if (data) {
      setAllIdeas(data.voterIdeas);
      updateIdeas(0);

      setTotalParticipants(data.totalParticipants);
      if (data.userSubmitted) {
        setSubmitted(true);
      }
      setSubmissionCount(data.submissionCount);
      const totalIdeaCount = data.voterIdeas.flat(2).length;
      console.log("Total Ideas: ", totalIdeaCount);

      setRemainingLikes(Math.min(MAX_VOTES, Math.floor(totalIdeaCount / 3)));
      setIsLoading(false);
    }
    // console.log("Voter data received: ", voterIdeas);
  };

  // useEffect(() => {
  //   console.log("voterIdeas: ", voterIdeas);
  //   if (voterIdeas && voterIdeas != {}) {
  //     const ideaList = voterIdeas.map((i) => {
  //       return i.ideaText;
  //     });
  //     setIdeas(ideaList);
  //   } else {
  //   }
  //   console.log(ideas);
  // }, [voterIdeas]);

  useEffect(() => {
    if (!user) {
      navigate(routes.login);
    }
    getVoterInfo();
  }, []);

  const updateIdeas = useCallback(
    (index) => {
      const flatIdeas = allIdeas;
      const startIndex = index * ideasPerPage;
      const endIndex = startIndex + ideasPerPage;
      setIdeas(flatIdeas.slice(startIndex, endIndex));
    },
    [allIdeas]
  );
  useEffect(() => {
    // Fetch initial ideas when component mounts
    if (!user) {
      console.log("Here 1");
      navigate(routes.beginHere);
    }
    if (!roomCode) {
      console.log("Here 2");

      navigate(routes.beginHere);
    }
    setUserId(user?.result._id);
    socket.on("userSubmittedVotes", (data) => {
      if (data.socketRoomCode == roomCode) {
        setSubmissionCount(data.submissionCount);
      }
    });
    socket.on("voteComplete", ({ socketRoomCode }) => {
      socketRoomCode == roomCode && navigate(routes.leaderboard);
    });

    // socket.onAny((eventName, ...args) => {
    //   console.log(`Received event: ${eventName}`, args);
    // });

    return () => {
      socket.off("userSubmittedVotes");
      socket.off("voteComplete");
    };
  }, [navigate, roomCode, user, userId]);

  const [screenDimensions, setScreenDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    console.log("All ideas", allIdeas);
  }, [allIdeas]);

  const [currentIndex, setCurrentIndex] = useState(0);
  const ideasPerPage = Math.ceil(allIdeas.length / 3);
  const [ideas, setIdeas] = useState(allIdeas.slice(0, ideasPerPage));

  // track pages for previous and next page buttons
  const [isFirstPage, setIsFirstPage] = useState(true);
  const [isLastPage, setIsLastPage] = useState(false);
  const next = useCallback(() => {
    if ((currentIndex + 1) * ideasPerPage < allIdeas.length) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
      updateIdeas(currentIndex + 1);
      setIsFirstPage(false);
    }
    setIsLastPage((currentIndex + 2) * ideasPerPage >= allIdeas.length);
  }, [allIdeas, currentIndex, updateIdeas]);

  const previous = useCallback(() => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
      updateIdeas(currentIndex - 1);
      setIsLastPage(false);
      setIsFirstPage(currentIndex === 1);
    }
  }, [currentIndex, updateIdeas]);

  useEffect(() => {
    updateIdeas(currentIndex);
  }, [updateIdeas, currentIndex]);
  // useEffect(() => {
  //   updateIdeas(currentIndex);
  // }, []);

  useEffect(() => {
    const handleResize = () => {
      setScreenDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    console.log(remainingLikes, " likes remaining.");
  }, [remainingLikes]);
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 150, y: 200 });
  const stageRef = useRef(null);

  // useEffect(() => {
  //   if (!user) {
  //     navigate(routes.login);
  //   } else {
  //     checkAuthStatus();
  //   }
  // }, []);

  // const checkAuthStatus = async () => {
  //   const authenticated = await dispatch(checkUserAuth(user, navigate));
  //   if (!authenticated) {
  //     navigate(routes.login);
  //   }
  // };

  useEffect(() => {
    const handleGestureStart = (e) => {
      e.preventDefault();
      let startX = e.pageX - position.x;
      let startY = e.pageY - position.y;
      let gestureStartRotation = 0;
      let gestureStartScale = scale;
    };

    const handleGestureChange = (e) => {
      e.preventDefault();
      let startX = e.pageX - position.x;
      let startY = e.pageY - position.y;
      let gestureStartRotation = 0;
      let gestureStartScale = scale;

      // Inverted Pinch-to-Zoom
      const invertedScale = gestureStartScale / e.scale;
      setScale(Math.max(MIN_SCALE, Math.min(invertedScale, MAX_SCALE)));

      const newPos = enforceViewportBoundaries({
        x: e.pageX - startX,
        y: e.pageY - startY,
      });
      setPosition(newPos);
    };

    const handleGestureEnd = (e) => {
      e.preventDefault();
    };

    window.addEventListener("gesturestart", handleGestureStart);
    window.addEventListener("gesturechange", handleGestureChange);
    window.addEventListener("gestureend", handleGestureEnd);

    return () => {
      window.removeEventListener("gesturestart", handleGestureStart);
      window.removeEventListener("gesturechange", handleGestureChange);
      window.removeEventListener("gestureend", handleGestureEnd);
    };
  }, [scale, position]);

  const PAN_LIMIT_X = 300; // Maximum X boundary
  const PAN_LIMIT_Y = 300; // Maximum Y boundary
  const MIN_SCALE = 0.3;
  const MAX_SCALE = 2;

  const handleWheel = (e) => {
    e.evt.preventDefault();
    const stage = stageRef.current;
    const scaleBy = 1.07;

    if (e.evt.ctrlKey) {
      // Handle zoom
      const oldScale = stage.scaleX();
      const pointer = stage.getPointerPosition();
      const mousePointTo = {
        x: (pointer.x - stage.x()) / oldScale,
        y: (pointer.y - stage.y()) / oldScale,
      };

      let newScale = e.evt.deltaY > 0 ? oldScale / scaleBy : oldScale * scaleBy;

      // Enforce zoom limits
      newScale = Math.max(MIN_SCALE, Math.min(newScale, MAX_SCALE));

      const newPos = {
        x: pointer.x - mousePointTo.x * newScale,
        y: pointer.y - mousePointTo.y * newScale,
      };

      setScale(newScale);
      setPosition(enforceViewportBoundaries(newPos));
    } else {
      // Handle panning
      const newPos = {
        x: position.x - e.evt.deltaX,
        y: position.y - e.evt.deltaY,
      };
      setPosition(enforceViewportBoundaries(newPos));
    }
  };

  const enforceViewportBoundaries = (pos) => {
    const stage = stageRef.current;
    const scale = stage.scaleX();
    const { LEFT, RIGHT, TOP, BOTTOM } = getBoundary(
      scale,
      screenDimensions.width,
      screenDimensions.height
    );

    return {
      x: Math.min(Math.max(pos.x, LEFT * scale), RIGHT * scale),
      y: Math.min(Math.max(pos.y, TOP * scale), BOTTOM * scale),
    };
  };

  const resetView = useCallback(() => {
    const { LEFT, RIGHT, TOP, BOTTOM } = getBoundary(
      1,
      screenDimensions.width,
      screenDimensions.height
    );
    setPosition(
      enforceViewportBoundaries({
        x: screenDimensions.width / 2 - 600,
        y: screenDimensions.height / 2 - 200,
      })
    );
    // setPosition({ x: 150, y: 200 });

    setScale(1);
  }, [screenDimensions]);

  const [emptyHeartImage, setEmptyHeartImage] = useState(null);
  const [fullHeartImage, setFullHeartImage] = useState(null);

  const [arrowL, setArrowL] = useState(null);
  const [arrowStraight, setArrowStraight] = useState(null);
  const [arrowBetween, setArrowBetween] = useState(null);
  const handleKeyDown = (e) => {
    if (e.key === "r" || e.key === "R") {
      setPosition({ x: 150, y: 200 });
      setScale(1); // Optionally reset scale as well
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []); // Empty dependency array to run this effect only once on mount

  useEffect(() => {
    const img2 = new window.Image();
    img2.src =
      "https://brainwrite-assets.s3.amazonaws.com/desktop-vote-arrow-L.svg";
    img2.onload = () => {
      setArrowL(img2);
    };

    const img3 = new window.Image();
    img3.src =
      "https://brainwrite-assets.s3.amazonaws.com/desktop-vote-arrow-straight.svg";
    img3.onload = () => {
      setArrowStraight(img3);
    };

    const img4 = new window.Image();
    img4.src =
      "https://brainwrite-assets.s3.amazonaws.com/desktop-vote-arrow-between.svg";
    img4.onload = () => {
      setArrowBetween(img4);
    };
    const img1 = new window.Image();
    img1.src =
      "https://brainwrite-assets.s3.amazonaws.com/empty-heart-image.svg";
    img1.onload = () => {
      setEmptyHeartImage(img1);
    };

    const img5 = new window.Image();
    img5.src =
      "https://brainwrite-assets.s3.amazonaws.com/full-heart-image.svg";
    img5.onload = () => {
      setFullHeartImage(img5);
    };
  }, []);

  const NODE_WIDTH = 296;
  const NODE_HEIGHT = 112;
  const SOLO_NODES_PER_ROW = 4;
  const CHILD_NODES_PER_ROW = 3;
  const ROW_SPACING = 156;
  const ARROW_L_WIDTH = 56;
  const ARROW_L_HEIGHT = 56;
  const ARROW_STRAIGHT_WIDTH = 32;
  const ARROW_STRAIGHT_HEIGHT = 10;
  const ARROW_BETWEEN_WIDTH = 47;
  const ARROW_BETWEEN_HEIGHT = 132;
  const L_ARROW_TOP_SPACE = 10;
  const CHILD_OFFSET = 200;
  const BETWEEN_ARROW_DISTANCE = 10;
  const WHITE_PART_WIDTH = 56;
  const X_GAP = 10;
  const MANUAL_SWAP_VALUE = 126;
  //Definitely a better way to do this, but when we render rows of child nodes,
  //we switch off their starting possition, even rows should start on the right,
  //for chain-like appearance.
  //MANUAL_SWAP_VALUE is used to adjust the offset, because I couldn't figure out
  //Which constant to multiply to adjust it myself.
  //Looking at that number, it doesn't seem to correspond to anything really.
  const handleHeartClick = useCallback(
    (idea) => {
      if (!idea?.liked && remainingLikes <= 0) {
        console.log("No remaining likes.");
        return;
      }

      setRemainingLikes((prevRemainingLikes) =>
        idea.liked ? prevRemainingLikes + 1 : prevRemainingLikes - 1
      );

      setIdeas((prevIdeas) =>
        prevIdeas.map((category) =>
          category.map((item) =>
            item.contributionID === idea.contributionID
              ? { ...item, liked: !item.liked }
              : item
          )
        )
      );

      setAllIdeas((prevAllIdeas) =>
        prevAllIdeas.map((category) =>
          category.map((item) =>
            item.contributionID === idea.contributionID
              ? { ...item, liked: !item.liked }
              : item
          )
        )
      );
    },
    [remainingLikes]
  );

  const renderIdea = (idea, x, y) => {
    // const handleHeartClick = () => {
    //   console.log(`Heart clicked on idea: ${idea.text}`);
    //   idea.liked = !idea.liked;
    // };
    if (isLoading) {
      return (
        <Group key={`${x}-${y}`} x={x} y={y}>
          {" "}
        </Group>
      );
    }
    return (
      <Group key={`${x}-${y}`} x={x} y={y}>
        <Rect
          width={(NODE_WIDTH / 3) * 2}
          height={NODE_HEIGHT}
          fill="#EBFFF8"
          strokeWidth={2}
          cornerRadius={[10, 0, 0, 10]}
          shadowBlur={4}
          shadowColor="black"
          shadowOffsetX={0}
          shadowOffsetY={1}
          shadowOpacity={0.2}
        />
        <Rect
          x={NODE_WIDTH - WHITE_PART_WIDTH * 2}
          width={WHITE_PART_WIDTH}
          height={NODE_HEIGHT}
          fill="white"
          strokeWidth={2}
          cornerRadius={[0, 10, 10, 0]}
          shadowBlur={4}
          shadowColor="black"
          shadowOffsetX={0}
          shadowOffsetY={1}
          shadowOpacity={0.2}
        />
        <Circle
          x={NODE_WIDTH - WHITE_PART_WIDTH * 1.5 - 16 / 2}
          y={NODE_HEIGHT / 2 - 16 / 2}
          radius={12}
          fill="gray"
          opacity={0}
          ref={(node) => {
            if (node) {
              node.to({
                opacity: 0,
                duration: 0.2,
              });
            }
          }}
        />
        <Image
          x={NODE_WIDTH - WHITE_PART_WIDTH * 1.5 - 16 / 2}
          y={NODE_HEIGHT / 2 - 16 / 2}
          width={16}
          height={16}
          image={idea?.liked ? fullHeartImage : emptyHeartImage}
          onClick={() => handleHeartClick(idea)} // Use the callback
          // className="cursor-pointer" // Apply the CSS class
          onMouseEnter={(e) => {
            // style stage container:
            const container = e.target.getStage().container();
            container.style.cursor =
              remainingLikes > 0 || idea?.liked ? "pointer" : "not-allowed";
          }}
          onMouseLeave={(e) => {
            const container = e.target.getStage().container();
            container.style.cursor = "default";
          }}
        />
        <Text
          text={idea?.text}
          width={120}
          height={80}
          x={10}
          y={10}
          fontSize={14}
          wrap="word"
          align="center"
          verticalAlign="middle"
        />
      </Group>
    );
  };

  //Iterate through each chain in ideas,
  //If we have a chain with more than one, treat the first element as the parent node
  //and the rest as children. Render the parent in its own row, and then the children on the subsequent rows
  //With 3 children per row, max. Parent nodes will always have a row to themselves.
  //We render solo ideas in their own rows, and we can put multiple in one row, if we have
  //Multiple solo ideas in a row. Maximum of four per row, and then we go to the next row.

  //We also have an L-shaped arrow point from the bottom of a parent, to its first child.
  //Between rows of children, we have the between arrow, which is shaped like a C.
  //It should start on the left for each chain, and for each row of children,
  //it should alternate being flipped and on the right.
  //Lastly, we hae straight arrows in between two child nodes in the same row.
  //These should have scaleX=1 when isFlipped, and -1 otherwise.
  const renderChains = () => {
    if (ideas.length > 0) {
      // console.log("Ideas: ", ideas);
      let x = 0;
      let y = 0;
      let soloCounter = 0;
      const elements = [];
      let lastChainRendered = false; // Track if the last thing rendered was a chain

      ideas.forEach((chain) => {
        let isFlipped = true; // Start with the arrow on the right side

        if (chain.length === 1) {
          // If the last chain rendered was not a solo node, add spacing
          if (lastChainRendered) {
            x = 0;
            y += ROW_SPACING;
            lastChainRendered = false; // Reset the flag
          }

          if (soloCounter >= SOLO_NODES_PER_ROW) {
            x = 0;
            y += ROW_SPACING;
            soloCounter = 0;
          }
          elements.push(renderIdea(chain[0], x, y));
          x += NODE_WIDTH + X_GAP;
          soloCounter++;
        } else {
          if (x !== 0) {
            x = 0;
            y += ROW_SPACING;
          }
          elements.push(renderIdea(chain[0], x, y));

          if (chain.length > 1) {
            //render line 1 here
            elements.push(
              <Line
                key={`line2-${x}-${y}`}
                points={[
                  0,
                  y - NODE_HEIGHT / 2 + (ROW_SPACING - 90) / 2 + 2,
                  (NODE_WIDTH + X_GAP) * 4 - 65,
                  y - NODE_HEIGHT / 2 + (ROW_SPACING - 90) / 2 + 2,
                ]} // Define the points for the second line
                stroke="#ABA5A1"
                strokeWidth={1} // Set line width
              />
            );
            elements.push(
              <Image
                key={`arrowL-${x}-${y}`}
                x={x + (NODE_WIDTH / 5) * 2 + 10}
                y={y + NODE_HEIGHT + L_ARROW_TOP_SPACE}
                width={ARROW_L_WIDTH}
                height={ARROW_L_HEIGHT}
                image={arrowL}
              />
            );
          }

          y += ROW_SPACING;
          x = CHILD_OFFSET;
          const CHAIN_WIDTH =
            CHILD_NODES_PER_ROW * NODE_WIDTH + MANUAL_SWAP_VALUE;
          chain.slice(1).forEach((child, index) => {
            if (index % CHILD_NODES_PER_ROW === 0 && index !== 0) {
              x = !isFlipped ? CHILD_OFFSET : CHAIN_WIDTH - CHILD_OFFSET;
              y += ROW_SPACING;

              elements.push(
                <Image
                  key={`arrowBetween-${x}-${y}`}
                  x={
                    isFlipped
                      ? 0.8 * CHILD_OFFSET +
                        3 * NODE_WIDTH +
                        BETWEEN_ARROW_DISTANCE +
                        2
                      : CHILD_OFFSET - BETWEEN_ARROW_DISTANCE
                  } // Alternate side
                  y={y - ARROW_BETWEEN_HEIGHT / 2}
                  width={ARROW_BETWEEN_WIDTH}
                  height={ARROW_BETWEEN_HEIGHT}
                  image={arrowBetween}
                  scaleX={isFlipped ? 1 : -1} // Flip the arrow horizontally
                />
              );

              isFlipped = !isFlipped; // Toggle the flip state for the next row
            }

            elements.push(
              renderIdea(
                child,
                isFlipped
                  ? x + (NODE_WIDTH + X_GAP) * (index % CHILD_NODES_PER_ROW)
                  : x - (NODE_WIDTH + X_GAP) * (index % CHILD_NODES_PER_ROW),
                y
              )
            );

            // Add the straight arrow between child nodes in the same row
            if (
              (index + 1) % CHILD_NODES_PER_ROW !== 0 &&
              index < chain.length - 2
            ) {
              elements.push(
                <Image
                  key={`arrowStraight-${x}-${y}-${index}`}
                  x={
                    isFlipped
                      ? CHILD_OFFSET +
                        (NODE_WIDTH + X_GAP) * (index % CHILD_NODES_PER_ROW) +
                        NODE_WIDTH -
                        40
                      : x -
                        NODE_WIDTH -
                        (NODE_WIDTH + X_GAP) * (index % CHILD_NODES_PER_ROW) +
                        NODE_WIDTH -
                        20
                  }
                  y={y + NODE_HEIGHT / 2 - ARROW_STRAIGHT_HEIGHT / 2}
                  width={ARROW_STRAIGHT_WIDTH}
                  height={ARROW_STRAIGHT_HEIGHT}
                  image={arrowStraight}
                  scaleX={isFlipped ? 1 : -1} // Flip the arrow horizontally if needed
                />
              );
            }
          });

          // Mark that we just rendered a chain
          lastChainRendered = true;
          //render line 2 here
          elements.push(
            <Line
              key={`line2-${x}-${y}`}
              points={[
                0,
                y + NODE_HEIGHT + (ROW_SPACING - 110) / 2,
                (NODE_WIDTH + X_GAP) * 4 - 65,
                y + NODE_HEIGHT + (ROW_SPACING - 110) / 2,
              ]} // Define the points for the second line
              stroke="#ABA5A1"
              strokeWidth={1} // Set line width
            />
          );
        }
      });
      return elements;
    } else {
      return [];
    }
  };
  const BG_SIZE = 20000;

  const CustomArrow = ({ height, mt }) => (
    <svg
      width="48"
      height={height}
      viewBox={`0 0 48 ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={`M12 0 V${height - 20} Q12 ${height - 12} 20 ${height - 12} H36`}
        stroke="#0C7A56"
        strokeWidth="2"
        strokeLinecap="round"
        fill="none"
      />
      <path
        d={`M30 ${height - 18} L36 ${height - 12} L30 ${height - 6}`}
        stroke="#0C7A56"
        strokeWidth="2"
        strokeLinecap="round"
        fill="none"
      />
    </svg>
  );

  const [ideaHeights, setIdeaHeights] = useState({});
  const ideaRefs = useRef({});

  const ChainSeparator = ({ isFirstOnPage, isHidden }) => (
    <div
      className={`w-full h-px bg-graybrown400 ${
        isFirstOnPage || isHidden ? "hidden" : "mt-6 mb-6"
      }`}
    />
  );

  useEffect(() => {
    const newHeights = {};
    Object.keys(ideaRefs.current).forEach((key) => {
      if (ideaRefs.current[key]) {
        newHeights[key] = ideaRefs.current[key].offsetHeight;
      }
    });
    setIdeaHeights(newHeights);
  }, [ideas]);

  const renderMobileIdeas = () => {
    if (ideas.length === 0) {
      return (
        <div className="flex justify-center items-center h-[60vh]">
          <p className="text-lg text-graybrown900">No ideas to display</p>
        </div>
      );
    }
    return (
      <div className="flex flex-col p-4">
        <div className="">
          {ideas.length > 0 &&
            ideas.map((chain, chainIndex) => {
              const isFirstChain = chainIndex === 0;
              const isPreviousChainSingle =
                chainIndex > 0 && ideas[chainIndex - 1].length === 1;
              const isCurrentChainSingle = chain.length === 1;
              const isPreviousChainMulti =
                chainIndex > 0 && ideas[chainIndex - 1].length > 1;

              // Determine if this separator should be hidden
              const shouldHideSeparator =
                isFirstChain ||
                (isPreviousChainSingle && isCurrentChainSingle) ||
                (isPreviousChainMulti && isCurrentChainSingle) ||
                isPreviousChainMulti; // Hide separator after multi-idea chains
              return (
                <React.Fragment key={chainIndex}>
                  <ChainSeparator
                    isFirstOnPage={isFirstChain}
                    isHidden={shouldHideSeparator}
                  />
                  <div
                    key={chainIndex}
                    className="flex flex-col items-start h-full relative"
                  >
                    {/* line */}
                    {chain.map((idea, ideaIndex) => (
                      <div key={ideaIndex}>
                        {ideaIndex > 1 && (
                          <svg
                            width="2"
                            height="90%"
                            viewBox="0 0 2 100"
                            preserveAspectRatio="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="absolute -bottom-10 left-[15px] z-10"
                          >
                            <line
                              x1="1"
                              y1="0"
                              x2="1"
                              y2="65%"
                              stroke="#0C7A56"
                              strokeWidth="2"
                            />
                          </svg>
                        )}
                      </div>
                    ))}
                    {chain.map((idea, ideaIndex) => (
                      <div
                        key={ideaIndex}
                        className="relative max-w-[80vw] text-left mb-2"
                      >
                        <div className="flex items-start">
                          {ideaIndex > 0 && (
                            <div className="mr-2">
                              <div className="relative left-1 h-full">
                                {ideaIndex === 1 ? (
                                  <div className="relative">
                                    <div className="relative mt-2">
                                      <CustomArrow height={30} />
                                    </div>
                                  </div>
                                ) : (
                                  <div className="mb-16 relative">
                                    <div className="relative -my-12">
                                      <CustomArrow height={86} />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                          <div
                            ref={(el) =>
                              (ideaRefs.current[`${chainIndex}-${ideaIndex}`] =
                                el)
                            }
                            className="flex justify-between w-full rounded-lg shadow-md overflow-hidden"
                          >
                            <div className="w-full p-4 pr-6 bg-green100 z-20">
                              <p className="text-sm text-graybrown900">
                                {idea?.text}
                              </p>
                            </div>
                            <div className="w-10 right bg-white flex items-center justify-center">
                              <button
                                onClick={() => handleHeartClick(idea)}
                                className="w-8 h-8 flex items-center justify-center rounded-full"
                              >
                                <img
                                  src={
                                    idea?.liked
                                      ? "https://brainwrite-assets.s3.amazonaws.com/full-heart-image.svg"
                                      : "https://brainwrite-assets.s3.amazonaws.com/empty-heart-image.svg"
                                  }
                                  alt="heart"
                                  className="w-[14px] h-[14px]"
                                />
                                {/* {idea.liked && (
                            <div className=" text-orange900 px-1 py-1 rounded-tr-xl rounded-bl-xl">
                              <span className="text-sm font-normal">{idea.id}</span>
                            </div>
                          )} */}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  {chain.length > 1 && (
                    <ChainSeparator isFirstOnPage={false} isHidden={false} />
                  )}
                </React.Fragment>
              );
            })}
        </div>
      </div>
    );
  };
  const handleSubmit = () => {
    console.log("Votes submitted: ", allIdeas);

    socket.emit("submitVotes", {
      roomCode,
      userId,
      votes: allIdeas,
    });

    setSubmitted(true);
  };

  return (
    !isLoading && (
      // desktop medium screens view
      <div>
        <div className="relative">
          <div className="hidden md:block">
            <header className="absolute top-10 left-0 w-full z-10 font-poppins">
              <div className="h-[56px] mt-16 flex items-center px-4 flex-row align-middle justify-center gap-2">
                {/* Left Div desktop */}
                <div className="px-4 bg-white shadow-sm border-graybrown400 border-[1px] rounded-xl flex-row flex items-center gap-2 h-[38px] md:h-full">
                  <img
                    className="h-4 w-4"
                    alt=""
                    src="https://brainwrite-assets.s3.amazonaws.com/full-heart-image.svg"
                  />
                  {remainingLikes} Votes Remaining
                </div>

                {/* Right Div desktop */}
                <div className="px-2 h-[38px] md:h-full bg-white shadow-sm border-graybrown400 border-[1px] rounded-xl items-center flex-row align-middle justify-center flex gap-2">
                  <div
                    className={`${
                      isFirstPage
                        ? "bg-profile cursor-default"
                        : "bg-orange500 hover:bg-orange400 cursor-pointer"
                    } h-[27px] w-[27px] md:h-[40px] md:w-[40px] rounded-md md:rounded-xl  flex items-center justify-center`}
                    onClick={previous}
                  >
                    <img
                      src={
                        isFirstPage
                          ? "https://brainwrite-assets.s3.amazonaws.com/gray-arrow.svg"
                          : "https://brainwrite-assets.s3.amazonaws.com/wht-arrow.svg"
                      }
                      alt="previous page"
                      className={`${
                        isFirstPage
                          ? "h-[11px] md:h-[16px]"
                          : "h-[11px] md:h-[16px] rotate-180"
                      } select-none`}
                    />
                  </div>
                  <div
                    className={`${
                      isLastPage
                        ? "bg-profile cursor-default"
                        : "bg-orange500 hover:bg-orange400 cursor-pointer"
                    } h-[27px] w-[27px] md:h-[40px] md:w-[40px] rounded-md md:rounded-xl  flex items-center justify-center`}
                    onClick={next}
                  >
                    <img
                      src={
                        isLastPage
                          ? "https://brainwrite-assets.s3.amazonaws.com/gray-arrow.svg"
                          : "https://brainwrite-assets.s3.amazonaws.com/wht-arrow.svg"
                      }
                      alt="next page"
                      className={`${
                        isLastPage
                          ? "h-[11px] md:h-[16px] rotate-180"
                          : "h-[11px] md:h-[16px]"
                      } select-none`}
                    />
                  </div>
                </div>
                {remainingLikes < 1 && (
                  <div
                    className="px-8 h-[38px] md:h-full bg-orange500 text-white font-semibold shadow-sm border-graybrown400 border-[1px] rounded-xl items-center flex-row align-middle justify-center flex gap-2 hover:bg-orange400 cursor-pointer"
                    onClick={handleSubmit}
                  >
                    Submit
                  </div>
                )}
              </div>
            </header>
            <Stage
              ref={stageRef}
              width={window.innerWidth}
              height={window.innerHeight}
              scaleX={scale}
              scaleY={scale}
              x={position.x}
              y={position.y}
              draggable
              onWheel={handleWheel}
            >
              <Layer>
                {/* Background Rect */}
                <Rect
                  x={-BG_SIZE} // adjust to cover the entire canvas
                  y={-BG_SIZE} // adjust to cover the entire canvas
                  width={2 * BG_SIZE} // cover the entire view with buffer
                  height={2 * BG_SIZE} // cover the entire view with buffer
                  fill="#F6F4F3"
                />
                {renderChains()}
              </Layer>
            </Stage>

            <div className="absolute bottom-4 right-4 z-10">
              <button
                onClick={resetView}
                className="bg-white hover:bg-profile text-orange900 font-semibold py-2 px-4 border border-gray-400 text-xs md:text-sm rounded drop-shadow-custom"
              >
                Reset View
              </button>
            </div>
          </div>
        </div>

        {/* mobile */}
        <div className="pt-36 relative md:hidden min-h-screen w-full bg-profile">
          <header className="fixed top-0 left-0 w-full z-50 font-poppins">
            <div className="h-[64px] mt-20 flex items-center px-4 flex-row align-middle justify-center gap-2">
              {/* votes remaining div */}
              <div className="px-4 bg-white shadow-sm border-graybrown400 border-[1px] rounded-xl flex-row flex items-center gap-2 h-[44px] md:h-full">
                <img
                  className="h-4 w-4"
                  alt=""
                  src="https://brainwrite-assets.s3.amazonaws.com/full-heart-image.svg"
                />
                {remainingLikes} Votes Remaining
              </div>

              {/* page arrows Div */}
              <div className="">
                <div className="px-2 h-[44px] md:h-full bg-white shadow-sm border-graybrown400 border-[1px] rounded-xl items-center flex-row align-middle justify-center flex gap-2 ">
                  <div
                    className={`${
                      isFirstPage
                        ? "bg-profile cursor-default"
                        : "bg-orange500 hover:bg-orange400 cursor-pointer"
                    } h-[32px] w-[32px] md:h-[40px] md:w-[40px] rounded-md md:rounded-xl  flex items-center justify-center`}
                    onClick={previous}
                  >
                    <img
                      src={
                        isFirstPage
                          ? "https://brainwrite-assets.s3.amazonaws.com/gray-arrow.svg"
                          : "https://brainwrite-assets.s3.amazonaws.com/wht-arrow.svg"
                      }
                      alt="previous page"
                      className={`${
                        isFirstPage
                          ? "h-[11px] md:h-[16px]"
                          : "h-[11px] md:h-[16px] rotate-180"
                      } select-none`}
                    />
                  </div>
                  <div
                    className={`${
                      isLastPage
                        ? "bg-profile cursor-default"
                        : "bg-orange500 hover:bg-orange400 cursor-pointer"
                    } h-[32px] w-[32px] rounded-md flex items-center justify-center`}
                    onClick={next}
                  >
                    <img
                      src={
                        isLastPage
                          ? "https://brainwrite-assets.s3.amazonaws.com/gray-arrow.svg"
                          : "https://brainwrite-assets.s3.amazonaws.com/wht-arrow.svg"
                      }
                      alt="next page"
                      className={`${
                        isLastPage ? "h-[11px] rotate-180" : "h-[11px]"
                      } select-none`}
                    />
                  </div>
                </div>
              </div>
              {remainingLikes < 1 && (
                <div
                  className="px-8 h-[38px] md:h-full bg-orange500 text-white font-semibold shadow-sm border-graybrown400 border-[1px] rounded-xl items-center flex-row align-middle justify-center flex gap-2 hover:bg-orange400 cursor-pointer"
                  onClick={handleSubmit}
                >
                  Submit
                </div>
              )}
            </div>
          </header>
          <div className="mb-10">
            {renderMobileIdeas(ideaHeights, ideaRefs)}
          </div>
        </div>
      </div>
    )
  );
};

export default DotVoting;
