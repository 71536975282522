/** @format */

import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch } from "react-redux";
import { getLeaderboard } from "../../actions/sessions.js";
import { useNavigate } from "react-router-dom";
import socket from "../../socket.js"; // Import the shared socket instance
import { checkUserAuth } from "../../actions/auth.js";
import * as routes from "../../constants/routeNames.js";

// Custom hook for managing history
// Custom hook for managing history
function useHistory(initialState) {
  const [state, setState] = useState(initialState);
  const [history, setHistory] = useState([initialState]);
  const [pointer, setPointer] = useState(0);

  const push = (action) => {
    const newState = reducer(state, action);
    const newHistory = history.slice(0, pointer + 1);
    newHistory.push(newState);
    setHistory(newHistory);
    setPointer(newHistory.length - 1);
    setState(newState);
  };

  const undo = () => {
    if (pointer > 0) {
      setPointer(pointer - 1);
      setState(history[pointer - 1]);
    }
  };

  const redo = () => {
    if (pointer < history.length - 1) {
      setPointer(pointer + 1);
      setState(history[pointer + 1]);
    }
  };

  return [state, push, undo, redo];
}
// Reducer function to handle state updates
function reducer(state, action) {
  let newState;
  switch (action.type) {
    case "SET_SESSION_NAME":
      // console.log("Setting session name. Action: ", action);
      newState = { ...state, sessionName: action.payload, ideas: action.ideas };
      break;
    case "SET_CATEGORIES":
      console.log("state:", state);
      newState = {
        ...state,
        categories: action.payload,
        ideas: action.ideas || state.ideas,
      };
      console.log("new state: ", newState);
      break;
    case "ADD_CATEGORY":
      newState = {
        ...state,
        categories: [action.payload, ...state.categories],
      };
      break;
    case "REMOVE_CATEGORY":
      const categoryToRemove = state.categories.find(
        (cat) => cat.id === action.payload
      );
      const ideasToMove = categoryToRemove ? categoryToRemove.ideas : [];
      newState = {
        ...state,
        categories: state.categories.filter((cat) => cat.id !== action.payload),
        ideas: [...state.ideas, ...ideasToMove].sort(
          (a, b) => b.score - a.score
        ),
      };
      break;
    case "UPDATE_CATEGORY":
      newState = {
        ...state,
        categories: state.categories.map((cat) =>
          cat.id === action.payload.id
            ? { ...cat, ...action.payload.updates }
            : cat
        ),
      };
      break;
    case "MOVE_IDEA":
      const { idea, sourceCategoryId, targetCategoryId } = action.payload;

      newState = {
        ...state,
        categories: state.categories.map((category) => {
          if (category.id === sourceCategoryId) {
            return {
              ...category,
              ideas: category.ideas.filter((i) => i !== idea),
            };
          } else if (category.id === targetCategoryId) {
            return {
              ...category,
              ideas: [...category.ideas, idea],
            };
          } else {
            return category;
          }
        }),
        ideas:
          sourceCategoryId === null
            ? state.ideas.filter((i) => i !== idea) // Removing from ideas list
            : targetCategoryId === null
              ? [...state.ideas, idea] // Adding to ideas list
              : state.ideas,
      };
      break;

    case "SET_IDEAS":
      newState = { ...state, ideas: action.payload };
      break;

    default:
      newState = state;
  }

  // Emit the updated state through the socket, including categories and ideas
  if (!action.avoidFeedback) {
    console.log("Emiting categories:", newState.categories);
    socket.emit("updateState", {
      roomCode: JSON.parse(localStorage.getItem("room"))?.roomCode,
      ideas: newState.ideas,
      categories: newState.categories,
      sessionName: newState.sessionName,
    });
  }

  return newState;
}

// number next to the idea
const calculateRanks = (allIdeas) => {
  const sortedIdeas = allIdeas.sort((a, b) => b.score - a.score);
  const ranks = new Map();
  let currentRank = 1;

  sortedIdeas.forEach((idea, index) => {
    if (index > 0 && idea.score < sortedIdeas[index - 1].score) {
      currentRank = index + 1;
    }
    ranks.set(idea.text, currentRank);
  });

  return ranks;
};

const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

const Leaderboard = () => {
  const disp = useDispatch();
  const navigate = useNavigate();
  const [ranks, setRanks] = useState(new Map());
  const [{ sessionName, categories, ideas }, dispatch, undo, redo] = useHistory(
    {
      sessionName: "",
      categories: [
        { id: "category1", title: "", color: "bg-graybrown150", ideas: [] },
        { id: "category2", title: "", color: "bg-graybrown150", ideas: [] },
        { id: "category3", title: "", color: "bg-graybrown150", ideas: [] },
      ],
      ideas: [],
    }
  );

  const [selectedCategory, setSelectedCategory] = useState(null);
  const mainDivRef = useRef(null);
  const [editingCategoryId, setEditingCategoryId] = useState(null);
  const [editingTitle, setEditingTitle] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const categoriesContainerRef = useRef(null);
  const toolbarRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));
  const [activeToolStatus, setActiveToolStatus] = useState("default");
  const [roomCode, setRoomCode] = useState(
    JSON.parse(localStorage.getItem("room"))?.roomCode
  );
  const [localSessionName, setLocalSessionName] = useState(sessionName);

  const checkAuthStatus = async () => {
    console.log("Checking user auth");
    const authenticated = await disp(checkUserAuth(user, navigate));
    if (!authenticated) {
      navigate(routes.login);
    }
  };

  useEffect(() => {
    if (!user) {
      console.log(!user);
      navigate(routes.login);
    } else {
      if (user) {
        checkAuthStatus();
      }
    }
  }, []);

  const getLeaderboardDisp = async () => {
    const data = await disp(getLeaderboard(user, navigate));
    if (data) {
      console.log(" disp data", data);
      // dispatch({ type: "SET_IDEAS", payload: data.ideas });
      dispatch({
        type: "SET_CATEGORIES",
        payload: data.categories,
        ideas: data.ideas,
        avoidFeedback: true,
      });
      dispatch({
        type: "SET_SESSION_NAME",
        payload: data.sessionName,
        ideas: data.ideas,
        avoidFeedback: true,
      });
    }
  };

  useEffect(() => {
    const allIdeas = [
      ...ideas,
      ...categories.flatMap(category => category.ideas)
    ];
    setRanks(calculateRanks(allIdeas));
  }, [ideas, categories]);

  useEffect(() => {
    if (!user) {
      navigate(routes.login);
    }
    getLeaderboardDisp();

    // Listen for 'updateState' event
    socket.on(
      "updateState",
      ({ categories, ideas, sessionName, socketRoomCode }) => {
        if (socketRoomCode == roomCode) {
          dispatch({
            type: "SET_SESSION_NAME",
            payload: sessionName,
            ideas: ideas,
            avoidFeedback: true,
          });
          dispatch({
            type: "SET_CATEGORIES",
            payload: categories,
            ideas: ideas,
            avoidFeedback: true,
          });
        }
      }
    );

    return () => {
      socket.off("updateState");
    };
  }, []);

  const handleEditClick = () => {
    setIsEditing(true);
  };
  const handleNameChange = (e) => {
    // Update the global state directly when the input loses focus
    dispatch({
      type: "SET_SESSION_NAME",
      payload: e.target.value,
      ideas: ideas, // Pass current ideas
      avoidFeedback: true,
    });
  };

  const handleNameSubmit = (e) => {
    e.preventDefault();
    // Since the form is submitted, we ensure the global state is updated
    dispatch({});
    setIsEditing(false);
  };

  const handleBlur = () => {
    dispatch({});
    setIsEditing(false);
  };

  const handleEditCategoryTitle = (e, categoryId, currentTitle) => {
    if (activeToolStatus !== "default") {
      e.preventDefault();
      return;
    }
    e.stopPropagation();
    setEditingCategoryId(categoryId);
    setEditingTitle(currentTitle);
  };

  const handleSaveCategoryTitle = () => {
    dispatch({
      type: "UPDATE_CATEGORY",
      payload: { id: editingCategoryId, updates: { title: editingTitle } },
      ideas: ideas,
    });
    setEditingCategoryId(null);
    setEditingTitle("");
  };

  const handleCancelEdit = (e) => {
    e.stopPropagation();
    setEditingCategoryId(null);
    setEditingTitle("");
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSaveCategoryTitle();
    } else if (e.key === "Escape") {
      handleCancelEdit(e);
    }
  };

  const addNewCategory = () => {
    const newCategoryId = `category${categories.length + 1}`;
    const newCategory = {
      id: newCategoryId,
      title: "",
      color: "bg-graybrown150",
      ideas: [],
    };
    dispatch({ type: "ADD_CATEGORY", payload: newCategory, ideas: ideas });
  };

  const removeSelectedCategory = () => {
    if (selectedCategory) {
      setShowDeleteModal(true);
    }
  };

  const confirmDeleteCategory = () => {
    if (selectedCategory) {
      // Find the category being deleted
      const categoryToDelete = categories.find(
        (cat) => cat.id === selectedCategory
      );

      if (categoryToDelete) {
        // Add the ideas from the deleted category back to the ideas list
        const updatedIdeas = [...ideas, ...categoryToDelete.ideas];

        // Sort the updated ideas list by score in descending order
        updatedIdeas.sort((a, b) => b.score - a.score);

        // Update the ideas state
        dispatch({
          type: "SET_IDEAS",
          payload: updatedIdeas,
        });

        // Remove the category
        dispatch({
          type: "REMOVE_CATEGORY",
          payload: selectedCategory,
        });

        // Reset selected category
        setSelectedCategory(null);
      }

      // Close the delete modal
      setShowDeleteModal(false);
    }
  };

  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId);
  };

  const removeCategoryButtonRef = useRef(null);

  const handleOutsideClick = (e) => {
    if (
      !showDeleteModal &&
      categoriesContainerRef.current &&
      !categoriesContainerRef.current.contains(e.target) &&
      toolbarRef.current &&
      !toolbarRef.current.contains(e.target) &&
      removeCategoryButtonRef.current &&
      !removeCategoryButtonRef.current.contains(e.target)
    ) {
      setSelectedCategory(null);
    }
  };

  //////////////////////////
  const [draggedItem, setDraggedItem] = useState(null);
  const [draggedItemVisible, setDraggedItemVisible] = useState(true);
  const [isDragging, setIsDragging] = useState(false);
  const [dragOverTarget, setDragOverTarget] = useState(null);
  const [stopScrolling, setStopScrolling] = useState(true);
  const [scrollDirection, setScrollDirection] = useState(0);

  const scrollRef = useRef(null);

  const scroll = useCallback(() => {
    if (scrollDirection !== 0) {
      window.scrollBy(0, scrollDirection);
      scrollRef.current = requestAnimationFrame(scroll);
    }
  }, [scrollDirection]);

  useEffect(() => {
    if (scrollDirection !== 0) {
      scrollRef.current = requestAnimationFrame(scroll);
    } else if (scrollRef.current) {
      cancelAnimationFrame(scrollRef.current);
    }

    return () => {
      if (scrollRef.current) {
        cancelAnimationFrame(scrollRef.current);
      }
    };
  }, [scroll, scrollDirection]);

  useEffect(() => {
    console.log(draggedItemVisible);
  }, [draggedItemVisible]);

  const handleDragStart = (e, idea, source) => {
    if (activeToolStatus !== "default") {
      e.preventDefault();
      return;
    }
    e.dataTransfer.setData("text/plain", JSON.stringify({ idea, source }));
    e.dataTransfer.effectAllowed = "move";

    // Create a ghost image
    const ghostElement = e.target.cloneNode(true);
    ghostElement.style.width = `${e.target.offsetWidth}px`;
    ghostElement.style.height = `${e.target.offsetHeight}px`;
    ghostElement.style.opacity = "0.7";
    ghostElement.style.position = "absolute";
    ghostElement.style.top = "-1000px";
    ghostElement.style.border = "2px solid #FF9000"; // orange500 color
    document.body.appendChild(ghostElement);

    e.dataTransfer.setDragImage(ghostElement, 0, 0);

    setTimeout(() => {
      setIsDragging(true);
      setDraggedItem({ idea, source });
      setDraggedItemVisible(false);
      document.body.removeChild(ghostElement);
    }, 0);
  };
  const handleDragEnd = (e) => {
    if (isDragging && e.dataTransfer.dropEffect === "none") {
      // The drag was cancelled, make the item visible again
      setDraggedItemVisible(true);
    }
    setIsDragging(false);
    setDraggedItem(null);
  };

  const handleDrop = (e, targetCategoryId) => {
    if (activeToolStatus !== "default") {
      e.preventDefault();
      return;
    }
    e.preventDefault();
    if (isDragging && draggedItem) {
      const { idea, source } = draggedItem;

      if (source !== targetCategoryId) {
        dispatch({
          type: "MOVE_IDEA",
          payload: {
            idea,
            sourceCategoryId: source === "ideas-list" ? null : source,
            targetCategoryId: targetCategoryId,
          },
        });
      }
    }
    setIsDragging(false);
    setDraggedItem(null);
    setDraggedItemVisible(true);
    setDragOverTarget(null);
    setScrollDirection(0);
  };

  const handleDragOver = (e, targetId) => {
    if (activeToolStatus !== "default") {
      e.preventDefault();
      return;
    }
    e.preventDefault();
    setDragOverTarget(targetId);
    const threshold = 200; // Scroll zone threshold in pixels
    const mouseY = e.clientY;

    if (mouseY < threshold) {
      setScrollDirection(-5); // Scroll up
    } else if (mouseY > window.innerHeight - threshold) {
      setScrollDirection(5); // Scroll down
    } else {
      setScrollDirection(0); // Stop scrolling
    }
  };

  const handleDragLeave = () => {
    setDragOverTarget(null);
    setScrollDirection(0);
  };

  const handleDropToIdeasList = (e) => {
    if (activeToolStatus !== "default") {
      e.preventDefault();
      return;
    }
    e.preventDefault();
    if (isDragging && draggedItem) {
      const { idea, source } = draggedItem;
      if (source !== "ideas-list") {
        // Check if the idea already exists in the ideas list
        const ideaExists = ideas.some((existingIdea) => existingIdea.text === idea.text);

        if (!ideaExists) {
          dispatch({
            type: "MOVE_IDEA",
            payload: {
              idea,
              sourceCategoryId: source,
              targetCategoryId: null, // Moving to the ideas list
            },
          });
        } else {
          // If the idea already exists, just remove it from the source category
          dispatch({
            type: "REMOVE_IDEA_FROM_CATEGORY",
            payload: {
              idea,
              sourceCategoryId: source,
            },
          });
        }
      }
    }
    setIsDragging(false);
    setDraggedItem(null);
    setDraggedItemVisible(true);
    setDragOverTarget(null);
    setScrollDirection(0);
  };


  useEffect(() => {
    return () => {
      setStopScrolling(true);
    };
  }, []);

  // Color options
  const colorOptions = [
    {
      name: "",
      class: "bg-blue100 border-blue200 border-2",
      hoverClass: "hover:bg-blue200",
    },
    {
      name: "",
      class: "bg-purple100 border-purple200 border-2",
      hoverClass: "hover:bg-purple200",
    },
    {
      name: "",
      class: "bg-green100 border-green300 border-2",
      hoverClass: "hover:bg-green300",
    },
    {
      name: "",
      class: "bg-graybrown150 border-graybrown300 border-2",
      hoverClass: "hover:bg-graybrown300",
    },
  ];

  const colorMap = {
    "bg-blue100": "#EBF5FF",
    "bg-purple100": "#F8EBFF",
    "bg-graybrown150": "#F2F2F2",
    "bg-green100": "#E6FFE6",
  };

  // card colors
  const ideaCardColors = [
    { main: "bg-[#0C7A56]", score: "bg-[#EBFFF8]" }, // Green
    { main: "bg-[#560C7A]", score: "bg-[#F8EBFF]" }, // Purple
    { main: "bg-[#0C437A]", score: "bg-[#EBF5FF]" }, // Blue
  ];


  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showDeleteModal]);

  return (
    <div className="w-full min-h-screen bg-profile">
      <div
        ref={mainDivRef}
        className={`mx-5 md:mx-10 flex flex-col`}
        style={{ cursor: "var(--custom-cursor, default)" }}
      >
        <div className="w-full flex items-end justify-center">
          <div className="flex items-center justify-center gap-x-2 mt-[116px]">
            {isEditing ? (
              <form onSubmit={handleNameSubmit} className="flex items-center">
                <input
                  type="text"
                  value={sessionName}
                  onChange={handleNameChange}
                  onBlur={handleBlur}
                  placeholder="Untitled Session"
                  className="font-normal text-lg text-orange900 rounded-md border-graybrown400"
                  autoFocus
                />
                <button
                  type="submit"
                  className="ml-2 py-2 px-5 bg-white rounded-full text-sm text-orange900 hover:ring-[0.6px] hover:ring-graybrown400"
                >
                  Save
                </button>
              </form>
            ) : (
              <div
                className="flex items-center cursor-pointer"
                onClick={handleEditClick}
              >
                <p className="font-normal text-2xl text-orange900">
                  {sessionName}
                </p>
                <img
                  src="https://brainwrite-assets.s3.amazonaws.com/edit-icon-gb.svg"
                  alt="edit"
                  className="ml-2"
                />
              </div>
            )}
          </div>

          <button className="absolute hidden md:block bg-orange500 rounded-full w-[72px] h-10 text-white text-xs font-semibold hover:bg-orange400 active:ring-1 active:ring-orange400 right-5 md:right-10">Done</button>
        </div>

        {/* name your session mobile and small screens */}
        <div className="flex-col md:justify-between md:gap-x-5 mt-5 w-full">

          {/* Ideas ranked by vote */}
          <div
            className={`bg-white border-[0.6px] border-graybrown400 drop-shadow-custom w-full h-full py-4 px-3 rounded-2xl mt-2 mb-6 ${dragOverTarget === "ideas-list" ? "ring-2 ring-orange300" : ""
              }`}
            onDragOver={(e) => handleDragOver(e, "ideas-list")}
            onDragLeave={handleDragLeave}
            onDrop={(e) => handleDropToIdeasList(e)}
            onDragEnd={handleDragEnd}
            style={{
              pointerEvents: activeToolStatus === "colorSelect" ? "none" : "auto",
            }}
          >
            {" "}

            {/* Shadow box of ideas*/}
            <div className="flex justify-center items-center -mx-3">
              <div className="w-full h-2 bg-white"></div>
            </div>
            <div className="flex-col md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-4 text-orange900 pt-2 pb-6 max-h-[40vh] overflow-y-auto scrollbar scrollbar-thumb-graybrown400  scrollbar-thumb-rounded-md scrollbar-w-[2px] w-full space-y-2 md:space-y-0">
              {ideas
                .filter((idea) => idea.text !== "")
                .sort((a, b) => b.score - a.score)
                .map((idea, index) => {
                  const colorIndex = index % ideaCardColors.length;
                  const { main, score } = ideaCardColors[colorIndex];
                  return (
                    <div
                      key={index}
                      className={`flex justify-between w-full rounded-lg shadow-md ${draggedItem?.idea === idea &&
                        draggedItem?.source === "ideas-list" &&
                        !draggedItemVisible
                        ? "opacity-50"
                        : "opacity-100"
                        }`}
                      draggable="true"
                      onDragStart={(e) => handleDragStart(e, idea, "ideas-list")}
                      onDragEnd={handleDragEnd}
                    >
                      <div className={`w-[19%] ${main} flex items-center justify-center text-white font-semibold text-base rounded-l-lg`}>
                        {ranks.get(idea.text)}
                      </div>
                      <div className={`w-[65%] p-4 pr-1 ${score} z-20`}>
                        <p className="text-sm text-orange900 text-left break-words overflow-y-auto max-h-[170px]">{idea.text}</p>
                      </div>
                      <div className={`w-[16%] right bg-white rounded-r-lg flex items-center justify-center gap-1`}>
                        <div className="flex items-center justify-center rounded-full">
                          <img
                            src="https://brainwrite-assets.s3.amazonaws.com/full-heart-image.svg"
                            alt="heart"
                            className="w-[14px] h-[14px]"
                          />
                        </div>
                        <div className="text-orange900 py-1 rounded-tr-xl rounded-bl-xl">
                          <span className="text-sm font-normal">{idea.score}</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>

          {/* Toolbar mobile and small screens*/}
          <div className="flex justify-between items-center mb-5 md:hidden">
            <div
              ref={toolbarRef}
              className="bg-white py-2 px-3 rounded-lg flex items-center justify-between "
            >

              {/* Undo mobile */}
              <button
                className="w-10 h-10 rounded-lg flex items-center justify-center"
                onClick={undo}
              >
                <img
                  src="https://brainwrite-assets.s3.amazonaws.com/undo.svg"
                  alt="Undo"
                />
              </button>

              {/* Redo mobile */}
              <button
                className="w-10 h-10 rounded-lg flex items-center justify-center"
                onClick={redo}
              >
                <img
                  src="https://brainwrite-assets.s3.amazonaws.com/redo.svg"
                  alt="Redo"
                />
              </button>

              {/* Mouse select mobile */}
              {/* <button
                className="w-10 h-10 rounded-lg flex items-center justify-center"
                onClick={handleMouseSelect}
              >
                <img
                  src="https://brainwrite-assets.s3.amazonaws.com/mouse-select.svg"
                  alt="select"
                  className="h-[16px]"
                />
              </button> */}

              {/* Color select mobile */}
              {/* <div className="relative"> */}
              {/* <button
                  className={`w-10 h-10 rounded-lg flex items-center justify-center hover:brightness-110 ${selectedColorClass}`}
                  onClick={toggleColorSelect}
                >
                  <img
                    src="https://brainwrite-assets.s3.amazonaws.com/color-select.svg"
                    alt="choose color"
                  />
                </button>
                {isColorSelectActive && (
                  <div className="absolute top-full left-0 bg-white rounded-b-lg rounded-r-lg drop-shadow-custom -mt-1">
                    {colorOptions.map((option) => (
                      <button
                        key={option.name}
                        className={`w-6 h-6 m-2 rounded-full px-2 py-2 text-left ${option.class} ${option.hoverClass}`}
                        onClick={() => handleColorSelect(option.class)}
                      >
                        {option.name}
                      </button>
                    ))}
                  </div>
                )} */}
              {/* </div> */}

              {/* Add another category mobile*/}
              <button
                className="w-10 h-10 rounded-lg flex items-center justify-center"
                onClick={addNewCategory}
              >
                <img
                  src="https://brainwrite-assets.s3.amazonaws.com/plus-cat.svg"
                  alt="Add category"
                />
              </button>

              {/* Remove category mobile */}
              <button
                className="w-10 h-10 rounded-lg flex items-center justify-center"
                ref={removeCategoryButtonRef}
                onClick={removeSelectedCategory}
              >
                <img
                  src="https://brainwrite-assets.s3.amazonaws.com/minus-cat.svg"
                  alt="Remove category"
                />
              </button>


            </div>
            <button className="bg-orange500 rounded-full w-[72px] h-10 text-white text-xs font-semibold hover:bg-orange400 active:ring-1 active:ring-orange400">Done</button>
          </div>

          {/* Categories mobile and small screens */}
          <div
            ref={categoriesContainerRef}
            className="block md:hidden min-w-[60%] mb-96"
          >
            {categories.map((category) => (
              <div
                key={category.id}
                className={`flex-col bg-white border-[0.6px] border-graybrown400 rounded-2xl p-5 mt-2 text-orange900
  ${selectedCategory === category.id
                    ? `ring-[1.2px] ring-graybrown400`
                    : ""
                  }
  ${dragOverTarget === category.id ? "ring-2 ring-orange300 border-none" : ""}
  ${activeToolStatus === "colorSelect" ? "" : ""}`}
                onClick={() => handleCategoryClick(category.id)}
                onDragOver={(e) => handleDragOver(e, category.id)}
                onDragLeave={handleDragLeave}
                onDrop={(e) => handleDrop(e, category.id)}
                onDragEnd={handleDragEnd}
                style={{
                  pointerEvents:
                    activeToolStatus === "colorSelect" ? "auto" : "auto",
                }}
              >
                {editingCategoryId === category.id ? (
                  <div className="flex items-center mb-4">
                    <input
                      type="text"
                      value={editingTitle}
                      onChange={(e) => setEditingTitle(e.target.value)}
                      onKeyDown={handleKeyDown}
                      placeholder={
                        category.ideas.length === 0 ? "New category title" : ""
                      }
                      className="font-normal text-sm bg-transparent border-b border-graybrown400 w-44 rounded-md focus:outline-none"
                      autoFocus
                    />
                    <button
                      onClick={handleSaveCategoryTitle}
                      className="ml-2 py-2 px-5 bg-graybrown50 border border-graybrown400 rounded-full text-orange900 hover:bg-graybrown400 hover:text-white text-xs"
                    >
                      Save
                    </button>
                    <button
                      onClick={(e) => handleCancelEdit(e)}
                      className="ml-2 text-xs text-graybrown400 py-2 px-0 hover:text-graybrown500"
                    >
                      Cancel
                    </button>
                  </div>
                ) : (
                  <div className="flex items-center mb-4">
                    <h2
                      className={`${category.title !== ""
                        ? " break-words max-w-[90%] text-orange900"
                        : "text-graybrown400 "
                        } cursor-pointer mr-2 text-sm font-semibold`}
                      onClick={(e) =>
                        handleEditCategoryTitle(e, category.id, category.title)
                      }
                    >
                      {category.title || "New Category Title"}
                    </h2>
                    {/* <img
                      src="https://brainwrite-assets.s3.amazonaws.com/edit-icon-gb.svg"
                      alt="edit"
                      className="cursor-pointer w-5"
                      onClick={(e) =>
                        handleEditCategoryTitle(e, category.id, category.title)
                      }
                    /> */}
                  </div>
                )}

                {category.ideas
                  .filter((idea) => idea.text !== "")
                  .sort((a, b) => b.score - a.score)
                  .map((idea, index) => {
                    const colorIndex = index % ideaCardColors.length;
                    const { main, score } = ideaCardColors[colorIndex];
                    return (
                      <div
                        key={index}
                        className={`flex justify-between w-full rounded-lg shadow-md overflow-hidden mb-2 ${draggedItem?.idea === idea && !draggedItemVisible
                          ? "opacity-50"
                          : "opacity-100"
                          }`}
                        draggable="true"
                        onDragStart={(e) => handleDragStart(e, idea, category.id)}
                        onDragEnd={handleDragEnd}
                      >
                        <div className={`w-[19%] ${main} flex items-center justify-center text-white font-semibold text-base`}>
                          {ranks.get(idea.text)}
                        </div>
                        <div className={`w-full p-4 pr-6 ${score} z-20`}>
                          <p className="text-sm text-orange900 text-left">{idea.text}</p>
                        </div>
                        <div className={`w-[16%] right bg-white flex items-center justify-center gap-1`}>
                          <div className="flex items-center justify-center rounded-full">
                            <img
                              src="https://brainwrite-assets.s3.amazonaws.com/full-heart-image.svg"
                              alt="heart"
                              className="w-[14px] h-[14px]"
                            />
                          </div>
                          <div className="text-orange900 py-1 rounded-tr-xl rounded-bl-xl">
                            <span className="text-sm font-normal">{idea.score}</span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            ))}
          </div>


          <div className="flex-col min-w-[50%]">
            {/* Save to boards button small and mobile*/}
            {/* <div className="flex justify-center md:hidden">
            <button className="py-4 px-6 bg-orange100 text-orange900 hover:bg-orange500 hover:text-white rounded-full font-semibold text-base mb-10">
              Save to boards
            </button>
          </div> */}

            {/* medium screen objects */}
            <div className="w-full flex justify-between">
              {/* Toolbar medium screens*/}
              <div className="md:flex justify-between items-center mb-0 hidden">
                <div
                  ref={toolbarRef}
                  className="bg-white py-2 px-3 rounded-lg flex items-center justify-between "
                >

                  {/* Undo mobile */}
                  <button
                    className="w-10 h-10 rounded-lg flex items-center justify-center"
                    onClick={undo}
                  >
                    <img
                      src="https://brainwrite-assets.s3.amazonaws.com/undo.svg"
                      alt="Undo"
                    />
                  </button>

                  {/* Redo mobile */}
                  <button
                    className="w-10 h-10 rounded-lg flex items-center justify-center"
                    onClick={redo}
                  >
                    <img
                      src="https://brainwrite-assets.s3.amazonaws.com/redo.svg"
                      alt="Redo"
                    />
                  </button>

                  {/* Mouse select mobile */}
                  {/* <button
                className="w-10 h-10 rounded-lg flex items-center justify-center"
                onClick={handleMouseSelect}
              >
                <img
                  src="https://brainwrite-assets.s3.amazonaws.com/mouse-select.svg"
                  alt="select"
                  className="h-[16px]"
                />
              </button> */}

                  {/* Color select mobile */}
                  {/* <div className="relative"> */}
                  {/* <button
                  className={`w-10 h-10 rounded-lg flex items-center justify-center hover:brightness-110 ${selectedColorClass}`}
                  onClick={toggleColorSelect}
                >
                  <img
                    src="https://brainwrite-assets.s3.amazonaws.com/color-select.svg"
                    alt="choose color"
                  />
                </button>
                {isColorSelectActive && (
                  <div className="absolute top-full left-0 bg-white rounded-b-lg rounded-r-lg drop-shadow-custom -mt-1">
                    {colorOptions.map((option) => (
                      <button
                        key={option.name}
                        className={`w-6 h-6 m-2 rounded-full px-2 py-2 text-left ${option.class} ${option.hoverClass}`}
                        onClick={() => handleColorSelect(option.class)}
                      >
                        {option.name}
                      </button>
                    ))}
                  </div>
                )} */}
                  {/* </div> */}

                  {/* Add another category mobile*/}
                  <button
                    className="w-10 h-10 rounded-lg flex items-center justify-center"
                    onClick={addNewCategory}
                  >
                    <img
                      src="https://brainwrite-assets.s3.amazonaws.com/plus-cat.svg"
                      alt="Add category"
                    />
                  </button>

                  {/* Remove category mobile */}
                  <button
                    className="w-10 h-10 rounded-lg flex items-center justify-center"
                    ref={removeCategoryButtonRef}
                    onClick={removeSelectedCategory}
                  >
                    <img
                      src="https://brainwrite-assets.s3.amazonaws.com/minus-cat.svg"
                      alt="Remove category"
                    />
                  </button>
                </div>
              </div>
            </div>

            {/* Categories medium screens */}
            <div
              ref={categoriesContainerRef}
              className="hidden md:grid md:grid-cols-2 lg:grid-cols-3 mt-3 gap-4 w-full mb-10 pb-20"
            >
              {categories.map((category) => (
                <div
                  key={category.id}
                  className={`w-full flex flex-col bg-white border-[0.6px] border-graybrown400 rounded-2xl p-5 text-orange900 min-h-[128px]
                ${selectedCategory === category.id ? `ring-2 ring-offset-2 ring-offset-white ring-graybrown400` : ""}
                ${dragOverTarget === category.id ? "ring-2 ring-graybrown400" : ""}
                ${activeToolStatus === "colorSelect" ? "" : ""}`}
                  onClick={() => handleCategoryClick(category.id)}
                  onDragOver={(e) => handleDragOver(e, category.id)}
                  onDragLeave={handleDragLeave}
                  onDrop={(e) => handleDrop(e, category.id)}
                  onDragEnd={handleDragEnd}
                  style={{
                    pointerEvents: activeToolStatus === "colorSelect" ? "auto" : "auto",
                  }}
                >
                  {editingCategoryId === category.id ? (
                    <div className="flex items-center mb-4">
                      <input
                        type="text"
                        value={editingTitle}
                        onChange={(e) => setEditingTitle(e.target.value)}
                        onKeyDown={handleKeyDown}
                        placeholder={
                          category.ideas.length === 0 ? "New Category Title" : ""
                        }
                        className="font-normal text-base bg-transparent border-b border-graybrown400 w-full max-w-60 rounded-md focus:outline-none -mt-2"
                        autoFocus
                      />
                      <button
                        onClick={handleSaveCategoryTitle}
                        className="ml-2 py-2 px-5 bg-graybrown50 border border-graybrown400 rounded-full text-orange900 hover:bg-graybrown400 hover:text-white text-xs"
                      >
                        Save
                      </button>
                      <button
                        onClick={(e) => handleCancelEdit(e)}
                        className="ml-2 text-xs text-graybrown400 py-2 px-0 hover:text-graybrown500"
                      >
                        Cancel
                      </button>
                    </div>
                  ) : (
                    <div className="flex items-center mb-4 text-base font-semibold">
                      <h2
                        className={`${category.title !== ""
                          ? " break-words max-w-[90%] font-semibold text-base text-orange900"
                          : "text-graybrown400"
                          } cursor-pointer mr-2`}
                        onClick={(e) =>
                          handleEditCategoryTitle(e, category.id, category.title)
                        }
                      >
                        {category.title || "New Category Title"}
                      </h2>
                      {/* <img
                        src="https://brainwrite-assets.s3.amazonaws.com/edit-icon-gb.svg"
                        alt="edit"
                        className="cursor-pointer"
                        onClick={(e) =>
                          handleEditCategoryTitle(e, category.id, category.title)
                        }
                      /> */}
                    </div>
                  )}
                  {category.ideas
                    .filter((idea) => idea.text !== "")
                    .sort((a, b) => b.score - a.score)
                    .map((idea, index) => {
                      const colorIndex = index % ideaCardColors.length;
                      const { main, score } = ideaCardColors[colorIndex];
                      return (
                        <div
                          key={index}
                          className={`flex justify-between w-full rounded-lg shadow-md overflow-hidden mb-2 ${draggedItem?.idea === idea && !draggedItemVisible
                            ? "opacity-50"
                            : "opacity-100"
                            }`}
                          draggable="true"
                          onDragStart={(e) => handleDragStart(e, idea, category.id)}
                          onDragEnd={handleDragEnd}
                        >
                          <div className={`w-[19%] ${main} flex items-center justify-center text-white font-semibold text-base`}>
                            {ranks.get(idea.text)}
                          </div>
                          <div className={`w-full p-4 pr-6 ${score} z-20`}>
                            <p className="text-sm text-orange900 text-left">{idea.text}</p>
                          </div>
                          <div className={`w-[16%] right bg-white flex items-center justify-center gap-1`}>
                            <div className="flex items-center justify-center rounded-full">
                              <img
                                src="https://brainwrite-assets.s3.amazonaws.com/full-heart-image.svg"
                                alt="heart"
                                className="w-[14px] h-[14px]"
                              />
                            </div>
                            <div className="text-orange900 py-1 rounded-tr-xl rounded-bl-xl">
                              <span className="text-sm font-normal">{idea.score}</span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Deleting category alert */}
        {
          showDeleteModal && (
            <div
              id="popup-modal"
              tabIndex="-1"
              className="fixed top-0 left-0 right-0 z-50 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full flex items-center justify-center bg-orange900 bg-opacity-50"
            >
              <div className="relative w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow">
                  <button
                    type="button"
                    className="absolute top-3 right-2.5 text-graybrown400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    onClick={() => setShowDeleteModal(false)}
                  >
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                  <div className="p-6 text-center">
                    <svg
                      aria-hidden="true"
                      className="mx-auto mb-4 text-graybrown400 w-14 h-14"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                    <h3 className="mb-5 text-lg font-normal text-orange900">
                      Are you sure you want to delete this category?
                    </h3>
                    <button
                      onClick={confirmDeleteCategory}
                      type="button"
                      className="text-white bg-orange500 hover:bg-orange300 focus:ring-4 focus:outline-none focus:ring-orange500 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                    >
                      Yes, I'm sure
                    </button>
                    <button
                      onClick={() => setShowDeleteModal(false)}
                      type="button"
                      className="text-graybrown500 bg-white hover:bg-graybrown50 focus:ring-4 focus:outline-none focus:ring-graybrown200 rounded-lg border-2 border-graybrown500 text-sm font-medium px-5 py-2.5 hover:text-orange900 hover:border-orange900 focus:z-10"
                    >
                      No, cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </div >
    </div>
  );
};

export default Leaderboard;