/** @format */

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import * as routes from "../../constants/routeNames.js";

const VerifyEmail = () => {
  const location = useLocation();
  const [message, setMessage] = useState("");

  useEffect(() => {
    const token = new URLSearchParams(location.search).get("token");

    const verifyEmail = async () => {
      try {
        const response = await axios.get(
          `/api/auth/verify-email?token=${token}`
        );
        setMessage(response.data.message);
      } catch (error) {
        setMessage(error.response.data.message || "An error occurred.");
      }
    };

    if (token) {
      verifyEmail();
    }
  }, [location]);

  return (
    <div className="verify-email-page">
      <h1>Email Verification</h1>
      <p>{message}</p>
    </div>
  );
};

export default VerifyEmail;
