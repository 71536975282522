/** @format */

import React, { useState, useEffect, useCallback, useRef } from "react";
import "./TopBar.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { checkUserAuth } from "../actions/auth.js";
import { LOGOUT } from "../constants/actionTypes.js";
import * as routes from "../constants/routeNames.js";

function TopBar() {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));
  const [auth, setAuth] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const profileMenuRef = useRef(null);
  const mobileMenuRef = useRef(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const createSessionClicked = useCallback(() => {
    navigate(routes.createSession);
  }, [navigate]);

  const dashboardClicked = useCallback(() => {
    navigate(routes.beginHere);
  }, [navigate]);

  const signInClicked = useCallback(() => {
    navigate(routes.login);
  }, [navigate]);

  const signUpClicked = useCallback(() => {
    navigate(routes.signup);
  }, [navigate]);

  // mobile sign in and sign up
  const mobileSignInClicked = useCallback(() => {
    navigate(routes.login);
    setIsMobileMenuOpen(false);
  }, [navigate]);

  const mobileSignUpClicked = useCallback(() => {
    navigate(routes.signup);
    setIsMobileMenuOpen(false);
  }, [navigate]);

  const profileClicked = useCallback(() => {
    navigate(routes.profile);
  }, [navigate]);

  const logoClicked = useCallback(() => {
    if (location.pathname !== routes.landingpage) {
      navigate(routes.landingpage);
    }
  }, [location.pathname, navigate]);

  const signOutClicked = useCallback(() => {
    dispatch({ type: LOGOUT });
    setAuth(false);
    setUser(null);
    localStorage.removeItem("profile");
    navigate(routes.login);
  }, [dispatch, navigate]);

  const toggleProfileMenu = useCallback(() => {
    setIsProfileMenuOpen((prev) => !prev);
  }, []);

  const toggleMobileMenu = useCallback(() => {
    setIsMobileMenuOpen((prev) => !prev);
  }, []);

  useEffect(() => {
    const checkAuthStatus = async () => {
      if (user) {
        console.log("Checking user auth");
        const authenticated = await dispatch(checkUserAuth(user, navigate));
        setAuth(authenticated);
        console.log("Auth: ", authenticated);
      } else {
        setAuth(false);
      }
    };

    checkAuthStatus();
  }, [user, location, dispatch, navigate]);

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("profile")));
  }, [location]);

  // clicking outside of profile menu closes it
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        profileMenuRef.current &&
        !profileMenuRef.current.contains(event.target)
      ) {
        setIsProfileMenuOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        mobileMenuRef.current &&
        !mobileMenuRef.current.contains(event.target) &&
        !event.target.closest(".button-img")
      ) {
        setIsMobileMenuOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="top-bar open-sans-button-eb py-4 w-full text-sm font-bold text-center bg-white drop-shadow-md">
      <div className="mx-5 md:mx-10 lg:mx-20 flex justify-between items-end md:items-center cursor-pointer">
        <img
          loading="lazy"
          src="https://brainwrite-assets.s3.amazonaws.com/LogoBeta.svg"
          className="my-auto h-[48px]"
          alt="Logo"
          onClick={logoClicked}
        />

        <div className="button-img">
          {/* Hamburger menu icon for xs screens */}
          <div
            className="flex md:hidden items-center justify-center group"
            onClick={toggleMobileMenu}
          >
            <div className="absolute w-10 h-10 rounded-full mb-2 group-hover:opacity-20 group-hover:bg-graybrown400"></div>
            <img
              src="https://brainwrite-assets.s3.amazonaws.com/menu.svg"
              alt="Menu"
              className="w-5 h-5 cursor-pointer mb-2"
            />
          </div>
          {/* Regular buttons (hidden on xs screens) */}
          <div className="hidden md:flex justify-center items-center gap-4">
            {!auth && (
              <>
                <button
                  className={`w-32 px-6 py-2 rounded-full border border-orange900 text-orange900 text-base font-normal hover:bg-orange900 hover:border-orange900 active:ring-[1.6px] active:ring-orange900 hover:text-white ${
                    location.pathname === routes.login ? "hidden" : ""
                  }`}
                  onClick={signInClicked}
                >
                  Sign in
                </button>
                <button
                  className={`w-32 px-6 py-2 text-orange900 bg-orange200 rounded-full whitespace-nowrap text-base font-normal border border-orange200 hover:ring-[1.6px] hover:ring-orange500 active:ring-[2.4px] active:ring-orange500 ${
                    location.pathname === routes.signup ? "hidden" : ""
                  }`}
                  onClick={signUpClicked}
                >
                  Sign up
                </button>
              </>
            )}
            {auth && (
              <>
                <button
                  className={`px-6 py-2 text-orange500 bg-orange100 rounded-lg hover:bg-orange500 hover:text-white whitespace-nowrap text-base font-normal border border-orange100 hover:border-orange500 ${
                    location.pathname === routes.beginHere || !auth
                      ? "hidden"
                      : ""
                  }`}
                  onClick={dashboardClicked}
                >
                  Dashboard
                </button>

                {/* profile */}
                <div
                  className={`${
                    location.pathname === routes.profile ||
                    location.pathname === routes.login ||
                    location.pathname === routes.signup ||
                    !auth
                      ? "hidden"
                      : ""
                  }`}
                >
                  <div
                    className="flex items-center justify-center"
                    onClick={toggleProfileMenu}
                  >
                    <div className="absolute w-10 h-10 rounded-full hover:opacity-20 hover:bg-graybrown400"></div>
                    <button className="cursor-pointer">
                      <img
                        src="https://brainwrite-assets.s3.amazonaws.com/profile-icon.svg"
                        alt="profile"
                        className="w-[24px] h-[24px]"
                      />
                    </button>
                  </div>

                  {isProfileMenuOpen && (
                    <div
                      ref={profileMenuRef}
                      className="flex flex-col items-center justify-center p-2 bg-white rounded-md w-[200px] gap-2 absolute right-10 lg:right-20 mt-12 drop-shadow-custom"
                    >
                      <button
                        className={`flex flex-row justify-start bg-profile hover:brightness-95 w-[184px] rounded-md p-2 text-xs font-normal`}
                        onClick={profileClicked}
                      >
                        <img
                          src="https://brainwrite-assets.s3.amazonaws.com/profile-icon.svg"
                          alt="navigate to profile"
                          className="w-[16px] h-[16px] mr-2"
                        />
                        Profile
                      </button>
                      <button
                        className={`flex flex-row justify-start w-[184px] bg-profile hover:brightness-95 rounded-md p-2 text-xs font-normal`}
                        onClick={signOutClicked}
                      >
                        <img
                          src="https://brainwrite-assets.s3.amazonaws.com/log-out.svg"
                          alt="log out"
                          className="mr-2"
                        />
                        Log out
                      </button>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <div
        ref={mobileMenuRef}
        className={`
    block md:hidden fixed top-20 right-0 w-full bg-white drop-shadow-md z-50
    transition-all duration-500 ease-in-out overflow-hidden
    ${isMobileMenuOpen ? "max-h-[300px] opacity-100" : "max-h-0 opacity-70"}
  `}
      >
        <div className="flex flex-col items-center py-4 gap-2">
          {!auth && (
            <>
              <button
                className={`w-full px-6 py-2 text-orange900 text-base font-normal hover:bg-orange100 ${
                  location.pathname === routes.login ? "hidden" : ""
                }`}
                onClick={mobileSignInClicked}
              >
                Sign in
              </button>
              <button
                className={`w-full px-6 py-2 text-orange900 text-base font-normal hover:bg-orange100 ${
                  location.pathname === routes.signup ? "hidden" : ""
                }`}
                onClick={mobileSignUpClicked}
              >
                Sign up
              </button>
            </>
          )}
          {auth && (
            <>
              <button
                className={`w-full px-6 py-2 text-orange500 text-base font-normal hover:bg-orange100 ${
                  location.pathname === routes.landingpage ||
                  location.pathname === routes.login ||
                  location.pathname === routes.signup
                    ? "hidden"
                    : ""
                }`}
                onClick={dashboardClicked}
              >
                Dashboard
              </button>
              <button
                className={`w-full px-6 py-2 text-orange500 text-base font-normal hover:bg-orange100 ${
                  location.pathname === routes.profile ||
                  location.pathname === routes.login ||
                  location.pathname === routes.signup ||
                  !auth
                    ? "hidden"
                    : ""
                }`}
                onClick={profileClicked}
              >
                Profile
              </button>
              <button
                className="w-full px-6 py-2 text-orange500 text-base font-normal hover:bg-orange100"
                onClick={signOutClicked}
              >
                Log out
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default TopBar;
