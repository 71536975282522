/** @format */

import axios from "axios";
import * as routes from "../constants/routeNames.js";

const serverUrl = `${process.env.REACT_APP_SERVER_BASE_URL}`;
const CancelToken = axios.CancelToken;
let cancel;

const API = axios.create({ baseURL: serverUrl });

API.interceptors.request.use((req) => {
  if (localStorage.getItem("profile")) {
    req.headers.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem("profile")).token
    }`;
  }
  // Add cancel token to the request
  req.cancelToken = new CancelToken(function executor(c) {
    cancel = c;
  });
  return req;
});

// Function to cancel the current request if needed
export const cancelRequest = () => {
  if (cancel) {
    cancel("Request canceled");
  }
};

//AUTH

export const signIn = (formData) => {
  console.log(formData);
  return API.post(`user/signin`, formData);
};

export const signUp = (formData) => {
  return API.post(`user/signup`, formData);
};
export const googleAuth = (formData) => {
  return API.post("/user/google-auth", formData);
};
export const checkAuth = (id) => API.get(`/user/${id}/`);

//ROOM CREATION

export const openRoom = (id) => {
  return API.post(`room/${id}/openRoom`);
};
export const startRoom = (id, formData) => {
  return API.post(`room/${id}/startRoom`, formData);
};
export const joinRoom = (id, formData) => {
  return API.post(`room/${id}/joinRoom`, formData);
};
export const getParticipants = (id, roomCode) => {
  return API.get(`room/${id}/${roomCode}/getParticipants`);
};
export const getSession = (id) => {
  return API.get(`session/${id}/getSession`);
};
export const getVoterIdeas = (id) => {
  return API.get(`session/${id}/getVoterIdeas`);
};
export const getLeaderboard = (id) => {
  return API.get(`session/${id}/getLeaderboard`);
};
export const getAllSavedRooms = (id) => {
  return API.get(`user/${id}/getAllSavedRooms`);
};
export const getSavedRoom = (id, roomId) => {
  return API.get(`session/${id}/${roomId}/getSavedRoom`);
};
export const updateProfile = (id, formData) => {
  return API.patch(`user/${id}/updateProfile`, formData);
};
