/** @format */
import * as api from "../api/index.js";
import * as routes from "../constants/routeNames.js";

export const getAllSavedRooms = (userInfo, navigate) => async (dispatch) => {
  console.log(userInfo);
  try {
    const id = userInfo?.result?._id;
    // console.log("id:");
    // console.log(id);
    const response = await api.getAllSavedRooms(id);
    // console.log(" RESPONSE:");
    // console.log(response);
    // console.log(response?.data);
    const data = response?.data;
    return data;
    //dispatch({ type: MAKEROOM, data });
  } catch (error) {
    // navigate(routes.beginHere);
    // console.log("Error in joinRoom:", error);

    return [];
  }
};
export const getSavedRoom =
  (userInfo, roomId, navigate) => async (dispatch) => {
    console.log(userInfo);
    try {
      const id = userInfo?.result?._id;
      // console.log("id:");
      // console.log(id);
      const response = await api.getSavedRoom(id, roomId);
      const data = response?.data;
      return data;
      //dispatch({ type: MAKEROOM, data });
    } catch (error) {
      navigate(routes.beginHere);
      // console.log("Error in joinRoom:", error);

      return [];
    }
  };
