/** @format */

import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import TopBar from "./components/TopBar";
import LandingPage from "./pages/emailList/LandingPage";
import LandingMain from "./pages/LandingMain.js";
import SignUpSuccesful from "./pages/emailList/SignUpSuccesful";
import useScrollTo from "./hooks/useScrollTo";
import Profile from "./pages/Profile.js";
import BeginHere from "./pages/BeginHere";
import JoinSession from "./pages/JoinSession";
import Login from "./pages/login-signup/Login.js";
import Signup from "./pages/login-signup/Signup.js";
import CreateSession from "./pages/CreateSession.js";
import SessionSettings from "./pages/SessionSettings.js";
import MainFlow from "./pages/productFlow/MainFlow.js";
import WaitingToStart from "./pages/WaitingToStart.js";
import VerifyEmail from "./pages/login-signup/VerifyEmail.js";
import VotingIntro from "./pages/productFlow/VotingIntro.js";
import VotingRandomSampling from "./pages/productFlow/VotingRandomSampling.js";
import Leaderboard from "./pages/productFlow/Leaderboard.js";
import SavedBoard from "./pages/productFlow/SavedBoard.js";
import BuildOn from "./pages/BuildOn.js";
import DotVoting from "./pages/productFlow/DotVoting.js";
import FeedbackForm from "./pages/FeedbackForm.js";

import * as routes from "./constants/routeNames.js";

import reducers from "./reducers/index.js";

const App = () => {
  const [readyToTransformRef, scrollToReadyToTransform] = useScrollTo();

  return (
    <Router>
      <div className="App overflow-x-hidden zoomed-in:overflow-x-auto bg-white">
        <TopBar className="topBar bg-white" />
        <Routes>
          {/* <Route
            path="/"
            element={<LandingPage readyToTransformRef={readyToTransformRef} />}
          /> */}
          {/* <Route path={routes.VotingIntro} element={<VotingIntro />} /> */}
          {/* <Route path="/verify-email" element={<VerifyEmail />} /> */}
          {/* <Route path="/signup-successful" element={<SignUpSuccesful />} /> */}

          <Route path={routes.landingpage} element={<LandingMain />} />
          <Route path={routes.profile} element={<Profile />} />
          <Route path={routes.beginHere} element={<BeginHere />} />
          <Route path={routes.login} element={<Login />} />
          <Route path={routes.signup} element={<Signup />} />
          <Route path={routes.createSession} element={<CreateSession />} />
          <Route path={routes.joinSession} element={<JoinSession />} />
          <Route path={routes.sessionSettings} element={<SessionSettings />} />
          <Route path={routes.waitingToStart} element={<WaitingToStart />} />
          <Route path={routes.session} element={<MainFlow />} />
          {/* <Route
            path={routes.votingRandomSampling}
            element={<VotingRandomSampling />}
          /> */}
          <Route path={routes.votingRandomSampling} element={<DotVoting />} />

          <Route path={routes.leaderboard} element={<Leaderboard />} />
          <Route path={`${routes.savedboard}/:id`} element={<SavedBoard />} />
          <Route path={routes.buildOn} element={<BuildOn />} />
          <Route path={routes.feedbackForm} element={<FeedbackForm />} />
          <Route path="*" element={<div>404 Not Found</div>} />
        </Routes>

        {/* 

   Dear John, 
   
   You may notice this page looks a little bit different. (Route paths no longer quote route names directly). 
   Perhaps you are looking to make a new route, and now you are confused how to do it. 
   The answer you seek likes in frontend/src/constants/routeNames.js. 
   I now have route names all in one file, that way I can change it in one place 
   and not have to go find every little button and function that uses the route and change it there too. 
   If you need to make a route, go to that file, and just copy and paste a line. Call the const whatever you want. 
   The name is imported automatically, so just import your file and do: 
             <Route path={`${routes.newPage}`} element={<NewPage />} />
   I also changed some of the names already (eg., Beginhere is now dashboard.)
   We should probably go over these at some point and make sure were in agreement on
   our route names, since users will see these. 
   
   Also, I am leaving you. I am taking the kids - except for John Junior. 
   I am leaving him with you, because I no longer love him. 
   Do not ever contact me again. 

   Best,
   Rob 

*/}
      </div>
    </Router>
  );
};

export default App;
