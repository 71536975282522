/** @format */

//import { MAKEROOM, SAVESETTINGS } from "../constants/actionTypes";
import * as api from "../api/index.js";
import * as routes from "../constants/routeNames.js";

export const getSession = (userInfo, navigate) => async (dispatch) => {
  console.log(userInfo);
  try {
    const id = userInfo?.result?._id;
    console.log("id:");
    console.log(id);
    console.log("GETTING SESSION:");
    const response = await api.getSession(id);
    console.log(" RESPONSE:");
    console.log(response);
    console.log(response?.data);
    const data = response?.data;
    return data;
    //dispatch({ type: MAKEROOM, data });
  } catch (error) {
    navigate(routes.beginHere);
    console.log("Error in joinRoom:", error);
  }
};

export const getVoterIdeas = (userInfo, navigate) => async (dispatch) => {
  console.log(userInfo);
  try {
    const id = userInfo?.result?._id;
    console.log("id:");
    console.log(id);
    console.log("GETTING VOTER IDEAS:");
    const response = await api.getVoterIdeas(id);
    console.log(" RESPONSE:");
    console.log(response);
    console.log(response?.data);
    const data = response?.data;
    return data;
    //dispatch({ type: MAKEROOM, data });
  } catch (error) {
    navigate(routes.beginHere);
    console.log("Error in joinRoom:", error);
  }
};
export const getLeaderboard = (userInfo, navigate) => async (dispatch) => {
  console.log(userInfo);
  try {
    const id = userInfo?.result?._id;
    console.log("id:");
    console.log(id);
    console.log("GETTING LEADERBOARD:");
    const response = await api.getLeaderboard(id);
    console.log(" RESPONSE:");
    console.log(response);
    console.log(response?.data);
    const data = response?.data;
    return data;
    //dispatch({ type: MAKEROOM, data });
  } catch (error) {
    navigate(routes.beginHere);
    console.log("Error in joinRoom:", error);
  }
};
