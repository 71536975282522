/** @format */
import React, { useEffect } from "react";
import "flowbite";


const BuildOn = () => {

    useEffect(() => {
        import("flowbite").then((flowbite) => {
            flowbite.initPopovers();
        });
    }, []);

    return (
        <div className="min-h-screen w-full overflow-x-hidden flex flex-col items-center justify-center">
            <img
                src="https://brainwrite-assets.s3.amazonaws.com/start-wav.svg"
                alt="Background"
                className="absolute inset-0 w-full h-full object-cover"
                loading="lazy"
            />
            {/* participants box*/}
            <div className="bg-profile drop-shadow-custom w-[240px] h-[95px] rounded-3xl flex flex-col items-center justify-center absolute top-24">
                <div className="flex flex-row gap-1">
                    <img src="https://brainwrite-assets.s3.amazonaws.com/partic.svg" alt="participants icon" className="w-3 h-3"></img>
                    <h2 className="text-xs font-normal">Session Participants</h2>
                </div>
                <div className="flex justify-center items-center">
                    {/* {users.map((user, index) => (
                            <div key={index} className={index !== 0 ? "-ml-2" : ""}>
                                <ParticipantAvatar user={user} size={10} />
                            </div>
                        ))} */}
                </div>
            </div>
            <div className="relative flex-grow h-full w-full flex flex-col items-center justify-center max-h-[90vh] md:max-h-[80vh] mt-12">

                {/* logo, waiting, your ideas, tooltip */}
                <div className="flex flex-col items-center justify-center max-w-[335px] md:max-w-[474px]">
                    <img
                        src="https://brainwrite-assets.s3.amazonaws.com/logo-orange.svg"
                        alt="loading graphic"
                        className="w-40"
                    />
                    <h2 className="text-xs md:text-base font-semibold text-orange500 mt-5">
                        Waiting for others to submit their ideas
                    </h2>
                    <h1 className="text-2xl font-normal mt-6 text-orange900 text-center">
                        Next, add more of your ideas, <b>or</b> build on a teammate's idea.
                    </h1>

                    <button
                        data-popover-target="popover-default"
                        type="button"
                        className="px-3 py-2 h-6"
                    >
                        <img
                            src="https://brainwrite-assets.s3.amazonaws.com/info-graybrown.svg"
                            alt="info icon"
                            className="mt-0 mb-20 hover:cursor-pointer"
                        />
                    </button>
                    <div
                        data-popover
                        id="popover-default"
                        role="tooltip"
                        className="absolute z-10 invisible inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-orange200 border border-gray-200 rounded-lg drop-shadow-custom opacity-0"
                    >
                        <div className="px-2 py-2 bg-orange500 border-b border-white rounded-t-lg">
                            <h3 className="font-semibold text-white">What is Brainwriting?</h3>
                        </div>
                        <div className="px-3 py-2 bg-orange200">
                            <p className="text-xs text-orange900">
                                Brainwriting is a method for <b>ideation</b> that is typically
                                done anonymously.
                                <br />
                                <br />A <b>question</b> or <b>problem</b> will be posed to your
                                team, and each participant will respond with their{" "}
                                <b>
                                    initial idea<span class="inline-block">(s)</span>
                                </b>
                                .
                                <br />
                                <br />
                                Next, a series of one or more <b>passing rounds</b> allows
                                participants to respond to others' ideas by building on them,
                                adding to them, or being inspired by them.
                                <br />
                                <br />
                                Lastly, everyone can <b>vote</b> on the ideas, then come together
                                to discuss, <b>categorize</b>, and implement them.
                            </p>
                        </div>
                        <div data-popper-arrow className="bg-orange200 pr-2"></div>
                    </div>
                </div>


            </div>
        </div>
    );
};

export default BuildOn;