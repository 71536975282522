/** @format */

import React, { useState, useEffect } from "react";
import BottomBar from "../components/BottomBar.jsx";
import { useNavigate, useLocation } from "react-router-dom";
import { checkUserAuth } from "../actions/auth.js";
import { makeRoom, startRoom } from "../actions/rooms.js";
import { useDispatch } from "react-redux";
//import io from "socket.io-client";
import { getParticipants } from "../actions/rooms.js";
import socket from "../socket.js"; // Import the shared socket instance
import * as routes from "../constants/routeNames.js";
import ParticipantsBox from "../components/ParticipantsBox.jsx";

import _ from "lodash";
const serverUrl = `${process.env.REACT_APP_SERVER_BASE_URL}`;

// const socket = io(serverUrl);

const CreateSession = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const defaultSettings = {
    selectedVotingMethod: "Dot voting",
    numberOfRounds: 7,
    ideasPerRound: 3,
    timePerRound: 1, //change back to 5
    anonymity: false,
  };
  const quickBPresetSettings = {
    selectedVotingMethod: "Dot voting",
    numberOfRounds: 3,
    ideasPerRound: 3,
    timePerRound: 2,
    anonymity: true,
  };
  const deepBPresetSettings = {
    selectedVotingMethod: "Random Sampling",
    numberOfRounds: 5,
    ideasPerRound: 6,
    timePerRound: 5,
    anonymity: true,
  };

  const [roomCode, setRoomCode] = useState(
    JSON.parse(localStorage.getItem("room"))?.roomCode
  );
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));
  const [loading, setLoading] = useState(true); // Add loading state
  const [quickBIsOpen, setQuickBIsOpen] = useState(false);
  const [deepBIsOpen, setDeepBIsOpen] = useState(false);
  const [presetSelection, setPresetSelection] = useState(0);
  const [teamQuestion, setTeamQuestion] = useState(""); // Add state for team question

  const OnLoadData = location.state?.OnLoadData;
  const [roomSettings, setRoomSettings] = useState(
    OnLoadData ? OnLoadData : defaultSettings
  );
  const [users, setUsers] = useState([]);

  useEffect(() => {
    socket.emit("joinRoom", { roomCode });

    socket.on("userJoined", (email) => {
      console.log("USER JOINED");
      updateUsers();
    });

    return () => {
      socket.off("userJoined");
      socket.emit("leaveRoom", { roomCode });
    };
  }, [roomCode]);

  const updateUsers = async () => {
    if (user && roomCode) {
      const participants = await dispatch(getParticipants(user, roomCode));
      console.log("Participants: ", participants);
      setUsers(participants);
    }
  };
  const checkAuthStatus = async () => {
    console.log("Checking user auth");
    const authenticated = await dispatch(checkUserAuth(user, navigate));
    if (!authenticated) {
      navigate(routes.login);
    }
  };

  useEffect(() => {
    if (!user) {
      console.log(!user);
      navigate(routes.login);
    } else {
      if (user) {
        checkAuthStatus();
      }
    }
  }, []);

  useEffect(() => {
    updateUsers();
  }, []);

  useEffect(() => {
    if (_.isEqual(roomSettings, quickBPresetSettings)) {
      setPresetSelection(1);
    }
    if (_.isEqual(roomSettings, deepBPresetSettings)) {
      setPresetSelection(2);
    }
  }, [roomSettings]);

  const changeQuickBState = () => {
    setQuickBIsOpen(!quickBIsOpen);
  };
  const changeDeepBState = () => {
    setDeepBIsOpen(!deepBIsOpen);
  };
  const startPressed = () => {
    console.log("Start pressed");
    dispatch(startRoom(user, roomSettings, teamQuestion, roomCode, navigate));
  };

  const backArrowPressed = () => {
    navigate(routes.beginHere);
  };
  const customizePressed = () => {
    navigate(routes.sessionSettings, { state: { OnLoadData: roomSettings } });
  };

  //IF YOU EVER GET AUTOMATICALLY REDIRECTED TO LOGIN (On this page or any page)
  //and you are builiding / testing,
  //Find the lines marked *** and comment them out
  //(These lines will be in multiple files)

  useEffect(() => {
    if (user) {
      setLoading(false); //If you comment out the above line, uncomment this
    }
  }, []);

  useEffect(
    () => {
      if (!user) {
        //navigate("/login"); //***
        setLoading(false); //If you comment out the above line, uncomment this
        // } else if (!roomCode) {
      } else {
        dispatch(makeRoom(user, navigate)).then(() => {
          try {
            const room = JSON.parse(localStorage.getItem("room"));
            if (room) {
              setRoomCode(room.roomCode);
            }
          } catch (error) {
            console.error(error);
            setRoomCode("");
          } finally {
            setLoading(false);
          }
        });
      }
    },
    //   else {
    //     setLoading(false);
    //   }
    // }
    []
  );

  const handlePresetSelection = (preset) => {
    setPresetSelection(preset);
    if (preset === 1) {
      setRoomSettings(quickBPresetSettings);
    }
    if (preset === 2) {
      setRoomSettings(deepBPresetSettings);
    }
  };

  if (loading) {
    return <div>Loading...</div>; // Render a loading state
  }

  return (
    <div className="min-h-screen flex flex-col items-center bg-profile overflow-x-hidden">
      <div className="min-h-screen w-full flex flex-col justify-around">
        <div className="flex justify-between w-full">
          <div className="mt-28 ml-5 md:ml-10 h-[20px] hover:cursor-pointer">
            <img
              src="https://brainwrite-assets.s3.amazonaws.com/back-arrow-createsession.svg"
              alt="back"
              onClick={backArrowPressed}
            />
          </div>
          <div className="mt-4">
            <ParticipantsBox participants={users} />
          </div>

          <div className="mr-5 md:mr-10 mt-28 hover:cursor-pointer">
            <img
              src="https://brainwrite-assets.s3.amazonaws.com/settings-icon-gray-createsession.svg"
              alt="settings"
              onClick={customizePressed}
              className="transition duration-300 ease-in-out hover:rotate-90 rounded-full w-[20px]"
            />
          </div>
        </div>
        <div className="flex-col justify-center items-center mt-4 sm:mt-0">
          <p className="text-base sm:text-lg md:text-xl lg:text-2xl text-graybrown400 mb-2 text-center">
            Session ID:
          </p>
          <h1 className="font-bold text-5xl md:text-6xl text-orange900 text-center">
            {roomCode}
          </h1>
          <img
            className="mx-auto mt-1"
            src="https://brainwrite-assets.s3.amazonaws.com/orangeline2.svg"
            alt="line"
          />
        </div>

        {/* medium screen deep/quick descriptions */}
        {/* agile */}
        <div className="mt-8 flex flex-col md:flex-row items-center justify-center">
          <div
            className={`flex flex-col items-center justify-center rounded-lg w-[293px] h-[100px] bg-${
              presetSelection === 1
                ? "white ring-[1.6px] ring-orange500"
                : "white"
            } hover:ring-[1.6px] active:ring-[2.4px] hover:ring-orange500 drop-shadow-custom hover:cursor-pointer`}
            onClick={() => handlePresetSelection(1)}
          >
            <div className="flex items-center justify-center space-x-2">
              <img
                src="https://brainwrite-assets.s3.amazonaws.com/quickbrainwrite.svg"
                alt="agile"
                className="h-[24px]"
              />
              <p className="text-2xl font-medium ml-4">Agile</p>
            </div>
            <div className="flex-col justify-center items-center">
              <p className="text-base font-medium mt-2 text-graybrown400">
                2 minutes per round
              </p>
            </div>
          </div>

          {/* comprehensive */}
          <div
            className={`mt-4 md:mt-0 flex flex-col items-center justify-center rounded-lg hover:cursor-pointer w-[293px] h-[100px] ${
              presetSelection === 2
                ? "bg-white ring-[1.6px] ring-orange500"
                : "bg-white"
            } hover:ring-[1.6px] active:ring-[2.4px] hover:ring-orange500 drop-shadow-custom mx-3 hover:cursor-pointer`}
            onClick={() => handlePresetSelection(2)}
          >
            <div className="flex items-center justify-center space-x-2">
              <img
                src="https://brainwrite-assets.s3.amazonaws.com/compre.svg"
                alt="comprehensive"
                className="h-[24px]"
              />
              <p className="text-2xl font-medium ml-4">Comprehensive</p>
            </div>
            <div className="flex-col justify-center items-center">
              <p className="text-base font-medium mt-2 text-graybrown400">
                5 minutes per round
              </p>
            </div>
          </div>
        </div>

        {/* customize */}
        <div className="flex justify-center mt-8 group">
          <button
            className="flex text-base text-orange500 py-3 w-[250px] rounded-3xl font-medium justify-center items-center space-x-2 border hover:ring-1 hover:ring-orange500 border-orange500"
            onClick={customizePressed}
          >
            <img
              src="https://brainwrite-assets.s3.amazonaws.com/set-orange.svg"
              alt="settings"
              className="w-[18px] transition duration-300 ease-in-out group-hover:rotate-90"
            />
            <span>Customize</span>
          </button>
        </div>

        {/* team question small/mobile */}
        <div className="flex mx-5 md:hidden items-center justify-center">
          <div className="">
            <div className="flex">
              <input
                className="text-base w-[335px] h-[48px] pl-3 mt-10 text-left mx-auto placeholder-graybrown400 text-orange900 border-none rounded-lg"
                id="team-question"
                type="text"
                placeholder="Enter your question for the team..."
                value={teamQuestion} // Controlled input
                onChange={(e) => setTeamQuestion(e.target.value)} // Update state on change
              ></input>
            </div>

            {/* start button small/mobile */}
            <div className="flex justify-end">
              <button
                className="group bg-orange500 mt-5 rounded-full text-orange800 font-medium px-5 py-3 max-w-[80px] hover:cursor-pointer"
                onClick={startPressed}
              >
                <img
                  src="https://brainwrite-assets.s3.amazonaws.com/white-arrow.svg"
                  alt="enter"
                  className="w-[23px] transition duration-300 group-hover:translate-x-2"
                />
              </button>
            </div>
          </div>
        </div>

        {/* team question medium */}
        <div>
          <div className="hidden md:flex justify-center items-center mt-6">
            <div>
              <input
                className="border-2 border-none placeholder-graybrown400 text-orange900 text-base py-3 w-[60vw] pl-3 rounded-lg mr-6 text-left"
                id="team-question"
                type="text"
                placeholder="Enter your question for the team..."
                value={teamQuestion} // Controlled input
                onChange={(e) => setTeamQuestion(e.target.value)} // Update state on change
              ></input>
            </div>

            {/* start button medium */}
            <div className="group">
              <button
                className="bg-orange500 rounded-full text-orange800 font-medium px-5 py-3 hover:cursor-pointer"
                onClick={startPressed}
              >
                <img
                  src="https://brainwrite-assets.s3.amazonaws.com/white-arrow.svg"
                  alt="enter"
                  className="w-[23px]  transition duration-300 group-hover:translate-x-2"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-auto w-full bottom-0">
        <BottomBar />
      </div>
    </div>
  );
};

export default CreateSession;
