/** @format */

import React, { useEffect, useState } from "react";
import * as routes from "../../constants/routeNames.js";

const VotingIntro = ({ preVote, totalParticipants, submittedParticipants }) => {
  const votingMethods = {
    dotVoting: {
      type: "Dot Voting: 3-2-1 Approach",
      context:
        "Your session facilitator has chosen weighted dot voting. Each participant will receive 6 dots: allocate 3 to your favorite idea, 2 to your second favorite, and 1 to your third favorite.",
    },
    randomSampling: {
      type: "Random Sampling",
      context:
        "Each team member will evaluate a random selection of ideas on a scale from 1-7. At the end, top ideas will be presented in order.",
    },
  };
  const votingMethod = "Random Sampling";

  const [percentage, setPercentage] = useState(0);
  const [method, setMethod] = useState(votingMethods.dotVoting);

  useEffect(() => {
    setPercentage((submittedParticipants / totalParticipants) * 100);
    console.log("Percentage: ", percentage);
  }, [submittedParticipants, totalParticipants]);

  useEffect(() => {
    if (votingMethod) {
      console.log("Setting method: ", votingMethod);
      setMethod(votingMethods[votingMethod]);
    }
    console.log("Method: ", method);
  }, [votingMethod]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white mx-5 mt-5 md:mt-10">
      {preVote ? (
        <div className="w-full mx-5 max-w-[800px]">
          <h1 className="text-base font-regular mb-2 text-center">
            Voting Method:
          </h1>
          <h2 className="text-2xl font-semibold mb-6 text-center">
            {method?.type}
          </h2>

          <div className="bg-orange100 flex rounded-2xl mb-12">
            <p className="p-5 text-base text-gray-700 items-center justify-center text-center">
              {method?.context}
            </p>
          </div>
        </div>
      ) : (
        <div className="w-full mx-5 max-w-[800px]">
          <h1 className="text-xl font-bold mb-6 text-center">
            Waiting for submissions
          </h1>
        </div>
      )}
      <div className="w-full mx-5 max-w-[800px]">
        <div className="relative w-[240px] h-auto mx-auto mb-4">
          <svg className="w-full h-full" viewBox="0 0 100 100">
            <circle
              cx="50"
              cy="50"
              r="47"
              fill="none"
              className="stroke-orange200"
              strokeWidth="2"
            />
            <circle
              cx="50"
              cy="50"
              r="47"
              fill="none"
              className="stroke-orange500"
              strokeWidth="2"
              strokeDasharray={`${percentage * 2.95} 295`}
              strokeLinecap="round"
              transform="rotate(-90 50 50)"
            />
          </svg>
          <div className="absolute inset-0 flex items-center justify-center">
            <span className="text-lg font-semibold">
              {submittedParticipants} of {totalParticipants}
            </span>
          </div>
        </div>

        <p className="text-center text-base font-medium mb-4">
          Participants done
        </p>
      </div>
    </div>
  );
};

export default VotingIntro;
