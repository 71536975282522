import React, { useState } from 'react';
import { Menu } from 'lucide-react';
import BottomBar from '../components/BottomBar';

const FeedbackForm = () => {
    const [isAnonymous, setIsAnonymous] = useState(false);
    const [feedback, setFeedback] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
        console.log('Feedback submitted:', { feedback, isAnonymous });
    };

    return (
        <div className="flex flex-col min-h-screen font-poppins relative">
            <img
                src="https://brainwrite-assets.s3.amazonaws.com/peaks-bg.svg"
                alt="Background waves"
                className="absolute inset-0 w-full h-full object-cover"
                loading="lazy"
            />
            <main className="z-10 mt-20 flex-grow flex flex-col items-center px-4 py-8 mx-5 md:mx-20">
                <img src="https://brainwrite-assets.s3.amazonaws.com/logo-orange.svg" alt="logo" className="w-20 h-20 mb-5" />
                <h1 className="text-base md:text-2xl font-semibold text-center mb-5 text-graybrown800">Thank you for using brainwrite.io!</h1>
                <p className="text-center text-orange900 mb-5 lg:max-w-[55%]">
                    We're always looking to improve, and your input can help us make brainwrite.io even better. Your feedback will go a long way in shaping the future of our platform.
                </p>

                <form onSubmit={handleSubmit} className="w-full relative h-full lg:max-w-[75%] xl:max-w-[56%]">
                    <textarea
                        className="w-full h-[320px] p-4 pr-16 border-none border-graybrown300 rounded-lg mb-4 resize-none bg-white text-orange900 placeholder-graybrown400"
                        placeholder="Please share your thoughts here!"
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                    />
                    <button
                        type="submit"
                        className="absolute bottom-7 right-2 hover:ring-2 hover:ring-orange500 hover:ring-opacity-50 active:ring-4 rounded-full p-1"
                        onClick={handleSubmit}
                    >
                        <img src="https://brainwrite-assets.s3.amazonaws.com/orange-submit.svg" alt="submit" className="" />
                    </button>
                </form>

                <div className="flex justify-between lg:justify-center items-center w-full lg:max-w-[75%] xl:max-w-[56%]">
                    <div className='flex items-center justify-between bg-white px-4 h-10 rounded-full'>
                        <span className="text-orange900 text-xs">Anonymous</span>
                        <label className="flex">
                            <input
                                type="checkbox"
                                className="appearance-none peer invisible"
                                checked={isAnonymous}
                                onChange={() => setIsAnonymous(!isAnonymous)}
                            />
                            <span className="hover:cursor-pointer w-12 h-[26px] flex items-center flex-shrink-0 bg-graybrown400 rounded-full p-1 after:w-5 after:h-5 after:bg-white after:rounded-full after:shadow-md peer-checked:bg-orange500 duration-300 ease-in-out after:duration-300 peer-checked:after:translate-x-5"></span>

                        </label>

                    </div>
                    <button
                        type="button"
                        className="bg-white text-orange900 px-14 h-10 rounded-full hover:bg-graybrown150 lg:hover:drop-shadow-custom text-xs lg:absolute lg:top-32 lg:right-20"
                    >
                        Skip
                    </button>
                </div>
            </main>

            <footer>
                <BottomBar />
            </footer>
        </div>
    );
};

export default FeedbackForm;