/** @format */

import { MAKEROOM, SAVESETTINGS } from "../constants/actionTypes";
import * as routes from "../constants/routeNames.js";

import * as api from "../api/index.js";

export const makeRoom = (userInfo, navigate) => async (dispatch) => {
  console.log(userInfo);

  try {
    const id = userInfo?.result?._id;
    // console.log("id:");
    // console.log(id);
    // console.log("FETCHING RESPONSE");
    const response = await api.openRoom(id);
    // console.log("RESPONSE:");
    // console.log(response);

    const data = response?.data;
    dispatch({ type: MAKEROOM, data });
    // navigate(routes.beginHere);
  } catch (error) {
    navigate(routes.beginHere);
    console.log("Error in makeRoom:", error);
  }
};

export const startRoom =
  (userInfo, settings, teamQuestion, roomCode, navigate) =>
  async (dispatch) => {
    console.log("opening room!");
    console.log(userInfo);
    console.log(settings);
    console.log(roomCode);

    try {
      const id = userInfo?.result?._id;
      console.log("id:");
      console.log(id);
      const formData = { roomCode, settings, teamQuestion };

      console.log("FETCHING RESPONSE");
      const response = await api.startRoom(id, formData);
      console.log("RESPONSE:");
      console.log(response);
      const data = response?.data;
      navigate(routes.session);
    } catch (error) {
      console.log("Error in makeRoom:", error);
    }
  };

export const joinRoom = (userInfo, roomCode, navigate) => async (dispatch) => {
  console.log("joining room!");
  console.log(userInfo);
  console.log(roomCode);
  try {
    const id = userInfo?.result?._id;
    console.log("id:");
    console.log(id);
    console.log("FETCHING RESPONSE");
    const response = await api.joinRoom(id, { roomCode });
    console.log("JOIN ROOM RESPONSE:");
    console.log(response.data?.data);
    const data = response?.data?.data;
    dispatch({ type: MAKEROOM, data });

    navigate(routes.waitingToStart);
  } catch (error) {
    console.log("Error in joinRoom:", error);
  }
};

// export const saveSettings = (settings) => async (dispatch) => {
//   dispatch({ type: SAVESETTINGS, settings });
// };
export const getParticipants = (userInfo, roomCode) => async (dispatch) => {
  console.log("joining room!");
  console.log(userInfo);
  console.log("getParticipants action: ", roomCode);
  try {
    const id = userInfo?.result?._id;
    const response = await api.getParticipants(id, roomCode);

    console.log("Response: ", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching participants:", error);
  }
};
