/** @format */

import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { getLeaderboard } from "../../actions/sessions.js";
import { useNavigate, useParams } from "react-router-dom";
import socket from "../../socket.js"; // Import the shared socket instance
import { checkUserAuth } from "../../actions/auth.js";
import { getSavedRoom } from "../../actions/savedRooms.js";
import * as routes from "../../constants/routeNames.js";

const SavedBoard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const categoriesContainerRef = useRef(null);
  const [sessionName, setSessionName] = useState("Untitled");
  const [ideas, setIdeas] = useState([]);
  const [categories, setCategories] = useState([
    { id: "category1", title: "", color: "bg-graybrown150", ideas: [] },
    { id: "category2", title: "", color: "bg-graybrown150", ideas: [] },
    { id: "category3", title: "", color: "bg-graybrown150", ideas: [] },
  ]);

  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));
  //   const [roomCode, setRoomCode] = useState(
  //     JSON.parse(localStorage.getItem("room"))?.roomCode
  //   );
  const roomId = useParams().id;

  const checkAuthStatus = async () => {
    console.log("Checking user auth");
    const authenticated = await dispatch(checkUserAuth(user, navigate));
    if (!authenticated) {
      navigate(routes.login);
    }
  };

  const getRoomInfo = async () => {
    console.log("Checking user auth");

    const data = await dispatch(getSavedRoom(user, roomId, navigate));
    setCategories(data.categories);
    setSessionName(data.sessionName);
    setIdeas(data.ideas);
  };

  useEffect(() => {
    if (!user) {
      console.log(!user);
      navigate(routes.login);
    } else {
      if (user) {
        checkAuthStatus();
      }
      getRoomInfo();
    }
  }, []);

  const getLeaderboardDisp = async () => {
    // const data = await dispatch(getLeaderboard(user, navigate));
    // if (data) {
    // }
  };

  useEffect(() => {
    if (!user) {
      navigate(routes.login);
    }
    getLeaderboardDisp();
  }, []);

  // Color options
  const colorOptions = [
    {
      name: "",
      class: "bg-blue100 border-blue200 border-2",
      hoverClass: "hover:bg-blue200",
    },
    {
      name: "",
      class: "bg-purple100 border-purple200 border-2",
      hoverClass: "hover:bg-purple200",
    },
    {
      name: "",
      class: "bg-green100 border-green300 border-2",
      hoverClass: "hover:bg-green300",
    },
    {
      name: "",
      class: "bg-graybrown150 border-graybrown300 border-2",
      hoverClass: "hover:bg-graybrown300",
    },
  ];

  const colorMap = {
    "bg-blue100": "#EBF5FF",
    "bg-purple100": "#F8EBFF",
    "bg-graybrown150": "#F2F2F2",
    "bg-green100": "#E6FFE6",
  };

  return (
    <div className={`mx-5 flex flex-col`}>
      {/* name your session mobile and small screens */}
      <div className="flex items-center justify-center gap-x-2 mt-32">
        <div className="flex items-center cursor-pointer">
          <p className="font-normal text-2xl text-graybrown400">
            {sessionName}
          </p>
        </div>
      </div>

      <div className="flex-col md:flex md:flex-row md:justify-between md:gap-x-5 mt-32 w-full">
        {/* Name your session */}

        {/* Categories mobile and small screens */}
        <div
          ref={categoriesContainerRef}
          className="block md:hidden min-w-[60%]"
        >
          {categories.map((category) => (
            <div
              key={category.id}
              className={`flex-col ${category.color} rounded-2xl p-5 mt-2 text-orange900`}
            >
              <div className="flex items-center mb-4">
                <h2
                  className={`${
                    category.title !== ""
                      ? " break-words max-w-[90%] font-semibold text-base text-orange900"
                      : "text-graybrown400"
                  } mr-2`}
                >
                  {category.ideas.length > 0
                    ? category.title
                    : category.title || "Untitled category"}
                </h2>
              </div>
              {category.ideas
                .filter((idea) => idea.text != "")
                .map((idea, index) => (
                  <div
                    key={index}
                    className={`group flex items-center gap-x-1`}
                  >
                    <div
                      key={index}
                      className={`w-full bg-white border border-blue700 p-3 rounded-xl text-xs font-normal ml-1 mt-3 overflow-hidden`}
                    >
                      {idea.text}
                    </div>
                    <div className="bg-white border border-blue700 p-3 rounded-xl text-xs font-normal ml-1 mt-3 mr-2  ">
                      +{idea.score}
                    </div>
                  </div>
                ))}
            </div>
          ))}
        </div>

        {/* Ideas ranked by vote */}
        <div
          className={`bg-orange100 w-full h-full py-4 px-3 rounded-2xl mt-2 mb-10 md:max-w-[50%]`}
        >
          {" "}
          <h2 className="font-semibold text-base mb-4 pt-1 pl-2">
            Ideas by vote
          </h2>
          {/* Shadow box */}
          <div className="flex justify-center items-center -mx-3">
            <div className="w-full h-2 bg-orange100 drop-shadow-custom"></div>
          </div>
          <div className="flex-col bg-orange100 text-orange900 pb-12 max-h-[500px] overflow-y-auto scrollbar scrollbar-thumb-graybrown400 scrollbar-thumb-rounded-md scrollbar-w-[2px] w-full">
            {ideas
              .filter((idea) => idea.text != "")
              .map((idea, index) => (
                <div key={index} className={`group flex items-center gap-x-1 `}>
                  <h3 className="text-orange900 font-semibold text-base">
                    {String(index + 1).padStart(2, "0")}
                  </h3>
                  <div className="w-full bg-white border border-blue700 p-3 rounded-xl text-xs font-normal ml-1 mt-3 mr-2">
                    {idea.text}
                  </div>
                  <div className="bg-white border border-blue700 p-3 rounded-xl text-xs font-normal ml-1 mt-3 mr-2">
                    +{idea.score}
                  </div>
                </div>
              ))}
          </div>
        </div>

        {/* Categories medium screens */}
        <div
          ref={categoriesContainerRef}
          className="hidden md:block w-full mb-10"
        >
          {categories.map((category) => (
            <div
              key={category.id}
              className={`flex-col ${category.color} rounded-2xl p-5 mt-2 text-orange900
`}
            >
              <div className="flex items-center mb-4">
                <h2
                  className={`${
                    category.title !== ""
                      ? " break-words max-w-[90%] font-semibold text-base text-orange900"
                      : "text-graybrown400"
                  }  mr-2`}
                >
                  {category.ideas.length > 0
                    ? category.title
                    : category.title || "Untitled category"}
                </h2>
              </div>
              {category.ideas
                .filter((idea) => idea.text != "")
                .map((idea, index) => (
                  <div
                    key={index}
                    className={`group flex items-center gap-x-1 `}
                  >
                    <div
                      key={index}
                      className={`w-full bg-white border border-blue700 p-3 rounded-xl text-xs font-normal ml-1 mt-3 overflow-hidden`}
                    >
                      {idea.text}
                    </div>
                    <div className="bg-white border border-blue700 p-3 rounded-xl text-xs font-normal ml-1 mt-3 mr-2 ">
                      +{idea.score}
                    </div>
                  </div>
                ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SavedBoard;
