/** @format */

import { MAKEROOM, SAVESETTINGS } from "../constants/actionTypes";
const roomsReducer = (state = { roomsData: null }, action) => {
  // console.log("Here in rooms reducer!");
  console.log(state);
  switch (action.type) {
    case MAKEROOM:
      localStorage.setItem("room", JSON.stringify({ ...action?.data }));

      return { ...state, roomsData: action?.data };
      // case DELETEROOM:
      return { ...state, authData: null };
      localStorage.setItem("room", JSON.stringify({}));
      localStorage.setItem("roomSettings", JSON.stringify({}));
    case SAVESETTINGS:
      localStorage.setItem("roomSettings", JSON.stringify({ ...action?.data }));

    default:
      return state;
  }
};

export default roomsReducer;
