/** @format */

import React, { useState, useEffect } from "react";
import "./PageStyles.css";
import "./ReadyToTransform.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BottomBar from "../../components/BottomBar.jsx";

const serverUrl = `${process.env.REACT_APP_SERVER_BASE_URL}`;
console.log("serverURL: ", serverUrl);

const ReadyToTransform = ({ onBackToTopClick }) => {
  const [emailInput, setEmailInput] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const postEmail = async (email) => {
    console.log("Posting email:", email);
    try {
      const response = await axios.post(
        `${serverUrl}/api/postEmail`,
        { email: email },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 201) {
        console.log("Email saved successfully");
        navigate("/signup-successful");
      } else {
        console.error("Failed to save email:", response.data);
        setError("Connection Error");
      }
    } catch (error) {
      console.error("Error posting email:", error);
      setError("Connection Error");
    }
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (validateEmail(emailInput)) {
        setError("");
        postEmail(emailInput);
      } else {
        setError("Please enter a valid email address.");
      }
    }
  };

  const handleSignUpClick = () => {
    if (validateEmail(emailInput)) {
      setError("");
      postEmail(emailInput);
    } else {
      setError("Please enter a valid email address.");
    }
  };

  const [isLgScreen, setIsLgScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsLgScreen(window.innerWidth >= 1024);
    }; // Initial check

    handleResize(); // Add event listener

    window.addEventListener("resize", handleResize); // Clean up event listener

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    // brainwrite logo
    <div className="w-full min-h-screen flex flex-col justify-between bg-white">
      <div className="flex-grow flex flex-col items-center justify-center w-full text-orange900 text-center">
        <img
          src="https://brainwrite-assets.s3.amazonaws.com/logo-orange.svg"
          alt="Brainwrite Logo"
          className="w-full max-w-[100px] mt-10 mb-16 md:mb-10 ready-to-transform-logo"
        ></img>
        {/* header */}
        <h1 className="leading-[1.6rem] semi-bold mx-[20vw] text-2xl mb-5 ready-to-transform-subtitle">
          Innovate Together Distraction-Free
        </h1>
        {/* body text */}
        <p className="text-base regular mb-20 md:mb-10 mx-10 ready-to-transform-description max-w-[421px] text-orange900">
          Sign up now to be among the first to experience {""}
          {isLgScreen && <br />}
          our groundbreaking brainwriting tool. Enter your {""}
          {isLgScreen && <br />}
          email below for updates and exclusive early access.
        </p>
        {/* input label */}
        <div className="flex items-center justify-center border  border-graybrown400 rounded-md px-4 py-3 w-[335px] md:w-[400px] text-orange900 text-base mb-2">
          <img
            src="https://brainwrite-assets.s3.amazonaws.com/email-icon-gb.svg"
            alt="email icon"
            className="w-[20px] ready-to-transform-email-icon"
          ></img>

          <input
            placeholder="Email"
            value={emailInput}
            onChange={(e) => {
              setEmailInput(e.target.value);
              setError("");
            }}
            onKeyPress={handleKeyPress}
            className="ml-2 w-full focus:outline-none placeholder-graybrown400 text-orange900 ready-to-transform-email-input"
          />
        </div>

        {error && (
          <div className="regular text-red-500 mb-[3vw] sm:mb-[3vw] md:mb-[2.5vw] lg:mb-[2vw] xl:mb-[1.5vw] text-xs sm:text-xs ready-to-transform-error">
            {error}
          </div>
        )}
        {/* signup button */}
        <div
          className="mt-1 mb-16 flex justify-center rounded-3xl px-4 py-3 w-[335px] md:w-[400px] text-base font-semibold text-white bg-orange500 hover:bg-orange300 cursor-pointer ready-to-transform-sign-up"
          onClick={handleSignUpClick}
        >
          Sign Up
        </div>
        {/* back to top caret */}
        <div
          className="flex justify-center rounded-lg mb-1 px-2 py-2 regular border-text-orange900/50 text-orange900/50 text-[1.8vw] hover:text-black hover:stroke-white cursor-pointer ready-to-transform-back-to-top"
          onClick={onBackToTopClick}
        >
          <img
            src="https://brainwrite-assets.s3.amazonaws.com/up-oran.svg"
            alt="Back to Top"
            className="mr-[0.8vw] mb-0 h-auto w-8"
          ></img>
        </div>
      </div>

      <BottomBar className="w-full" />
    </div>
  );
};

export default ReadyToTransform;
