/** @format */

import { AUTH, LOGOUT, CHECKAUTH } from "../constants/actionTypes";

const authReducer = (state = { authData: null }, action) => {
  // console.log("Here in auth reducer!");
  switch (action.type) {
    case AUTH:
      //console.log(action?.data);
      localStorage.setItem("profile", JSON.stringify({ ...action?.data }));
      return { ...state, authData: action?.data };
    case "UPDATEPROFILE":
      const existingProfile = JSON.parse(localStorage.getItem("profile"));
      const updatedProfile = {
        ...existingProfile,
        result: action?.data.result,
      };
      localStorage.setItem("profile", JSON.stringify(updatedProfile));
      return { ...state, authData: updatedProfile };

    case LOGOUT:
      localStorage.clear();
      return { ...state, authData: null };

    default:
      return state;
  }
};

export default authReducer;
