/** @format */

import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { checkUserAuth } from "../actions/auth.js";
import { LOGOUT } from "../constants/actionTypes.js";
import * as routes from "../constants/routeNames.js";

const colors = [
  { light: "FDC4C4", dark: "BA3B3B" }, // red
  { light: "EBFFF8", dark: "0C7A56" }, // green
  { light: "EBFFFF", dark: "0C437A" }, // blue
  { light: "ffd6c1", dark: "cf6c00" }, // orange
  { light: "F8EBFF", dark: "560C7A" }, // purple
  { light: "ffffda", dark: "b9b900" }, // yellow
  { light: "f1cffe", dark: "e146da" }, // pink
  { light: "0C7A56", dark: "EBFFF8" }, // dark-green
  { light: "BA3B3B", dark: "FDC4C4" }, // dark-red
  { light: "0C437A", dark: "EBFFFF" }, // dark-blue
  { light: "df7c10", dark: "fdd6a1" }, // dark-orange
  { light: "560C7A", dark: "F8EBFF" }, // dark-purple
  { light: "b9b900", dark: "ffffda" }, // dark-yellow
  { light: "e146da", dark: "f1cffe" }, // dark-pink
];

const getInitials = (name) => {
  const names = name.split(" ");
  return names
    .map((n) => n[0])
    .slice(0, 3)
    .join("")
    .toUpperCase();
};

const ParticipantAvatar = ({ user, size = 10 }) => {
  const [imageError, setImageError] = useState(false);
  const [isHovered, setIsHovered] = useState(false); // State to manage hover
  const colorIndex = user.colorIndex % colors.length;
  const { light, dark } = colors[colorIndex];
  const initials = getInitials(user.fullName);

  const handleImageError = () => {
    setImageError(true);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className="relative flex flex-col items-center"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={`w-10 h-10 rounded-full flex items-center justify-center relative`}
        style={
          !user.profilePhoto || imageError
            ? { backgroundColor: `#${light}`, border: `2px solid #${dark}` }
            : { border: `2px solid #${dark}` }
        }
      >
        {!user.profilePhoto || imageError ? (
          <span style={{ color: `#${dark}` }} className="text-sm font-semibold">
            {initials}
          </span>
        ) : (
          <img
            src={user.profilePhoto}
            alt={initials}
            className="w-full h-full rounded-full object-cover"
            onError={handleImageError}
          />
        )}
      </div>

      {/* Dropdown that appears on hover */}
      {isHovered && (
        <div
          className="absolute top-12 text-xs font-medium rounded-md shadow-lg p-1 max-w-[200px] min-w-[80px] text-center"
          style={{
            backgroundColor: `#${light}`,
            color: `#${dark}`,
            width: "max-content",
            whiteSpace: "normal",
            wordBreak: "keep-all",
            overflowWrap: "break-word",
          }}
        >
          {user.fullName}
        </div>
      )}
    </div>
  );
};

const ParticipantsBox = ({ participants }) => {
  //   participants = [
  //     {
  //       emaiL: "jimbo@gmail.com",
  //       fullName: "Rob Glazer",
  //       profilePhoto:
  //         "https://brainwrite-users.s3.us-east-1.amazonaws.com/profilePhotos/1724275226188_Screenshot%202024-07-31%20at%209.17.03%C3%A2%C2%80%C2%AFPM.png",
  //       colorIndex: 0,
  //     },
  //     {
  //       emaiL: "shlimbo@gmail.com",
  //       fullName: "Shlimbo",
  //       profilePhoto: "www.shleb.coom",
  //       colorIndex: 1,
  //     },
  //     {
  //       emaiL: "flimbo@gmail.com",
  //       fullName: "John Deer",
  //       profilePhoto: "",
  //       colorIndex: 2,
  //     },
  //     {
  //       emaiL: "grimbo@gmail.com",
  //       fullName: "Juju Smith Schuster",
  //       profilePhoto:
  //         "https://brainwrite-users.s3.us-east-1.amazonaws.com/profilePhotos/1724360373588_Brainwrite%20Logo%20PF%201.png",
  //       colorIndex: 3,
  //     },
  //     {
  //       emaiL: "trimbo@gmail.com",
  //       fullName: "Esteban Julio Ricardo Montoya de la Rosa Ramírez",
  //       profilePhoto:
  //         "https://brainwrite-users.s3.us-east-1.amazonaws.com/profilePhotos/1724360373588_Brinwrite%20Logo%201.png",
  //       colorIndex: 4,
  //     },
  //     {
  //       emaiL: "wimbo@gmail.com",
  //       fullName: "Walluigi",
  //       profilePhoto:
  //         "https://news.mit.edu/sites/default/files/styles/news_article__image_gallery/public/images/201011/20101123131216-1_0.jpg?itok=ma_P1FSF",
  //       colorIndex: 5,
  //     },
  //     {
  //       emaiL: "himbo@gmail.com",
  //       fullName: "h j",
  //       profilePhoto:
  //         "https://news.mit.edu/sites/default/files/styles/news_article__image_gallery/public/images/201011/20101123131216-1_0.jpg?itok=ma_P1FSF",
  //       colorIndex: 6,
  //     },
  //     {
  //       emaiL: "quimbo@gmail.com",
  //       fullName: "quimbo juggins",
  //       profilePhoto: "",
  //       colorIndex: 7,
  //     },
  //     {
  //       emaiL: "crimbo@gmail.com",
  //       fullName: "crimbo juggins",
  //       profilePhoto: "xcvdskjnvdkajn",
  //       colorIndex: 8,
  //     },
  //     {
  //       emaiL: "blimbo@gmail.com",
  //       fullName: "John Henry McCalister",
  //       profilePhoto: "",
  //       colorIndex: 9,
  //     },
  //   ];

  return (
    <div className="bg-profile drop-shadow-custom w-[240px] w-auto h-auto md:h-[95px] rounded-3xl flex flex-col items-center justify-start mt-12 p-4">
      <div className="flex flex-row gap-1">
        <img
          src="https://brainwrite-assets.s3.amazonaws.com/partic.svg"
          alt="participants icon"
          className="w-3 h-3"
        ></img>
        <h2 className="text-xs font-normal">Session Participants</h2>
      </div>
      <div className="flex justify-center items-center">
        {participants &&
          participants.map((user, index) => (
            <div key={index} className={index !== 0 ? "-ml-2" : ""}>
              <ParticipantAvatar user={user} size={10} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default ParticipantsBox;
